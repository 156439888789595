import {
    createContext,
    memo,
    useCallback,
    useContext,
    useEffect,
    useReducer,
} from 'react';
import authApi from './api';
import { AuthActions, authReducer } from './reducer';
import * as Sentry from '@sentry/react';
import { useNotifications } from '../notifications';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { getLogger } from '../utils';
import _ from 'lodash';
import {
    getLastUsedTenantId,
    setLastUsedAccountId,
    setLastUsedTenantId,
} from '../utils/last-used-tenants';

const logger = getLogger('AuthProvider');

const AuthContext = createContext({
    isLoading: true,
    isAuthenticated: false,
    attributes: null,
    isTenantSelected: false,
    accountId: null,
    allPermissions: [],
    currentPermissions: [],
    login: () => null,
    logout: () => null,
    oidcCallback: () => null,
    selectTenant: () => null,
    selectAccount: () => null,
    updateAttributes: () => null,
});

export const AuthProvider = memo(({ children }) => {
    const params = useParams();

    const [state, dispatch] = useReducer(authReducer, {
        isLoading: true,
        attributes: null,
        isAuthenticated: false,
        accountId: params.accountId,
        allPermissions: [],
        currentPermissions: [],
    });

    useEffect(() => {
        logger.log('AccountId Params changed', params.accountId);
        selectAccount(params.accountId);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [params.accountId]);

    const { showError } = useNotifications();
    const { t } = useTranslation();

    const login = useCallback(() => {
        if (state.isAuthenticated) return;
        authApi.login();
    }, [state]);

    const logout = useCallback(async () => {
        if (!state.isAuthenticated) return;
        await authApi.logout();
        dispatch({ type: AuthActions.Logout });
    }, [state]);

    const oidcCallback = useCallback(
        async (callback_data) => {
            if (state.isLoading) {
                return false;
            }
            dispatch({ type: AuthActions.Loading, payload: true });
            try {
                let data = await authApi.callback(callback_data);
                dispatch({ type: AuthActions.Login, payload: data });
                return true;
            } catch (ex) {
                let title = t(
                    '90b98d01b61e24f409ea6950c96b2e10',
                    'Authorization error'
                );
                let content = t(
                    'f7eba085d0718ba9dbda1f2cfbaa6d5a',
                    'You do not have authorization to access this resource.'
                );
                if (ex?.response?.data?.error === 'no_accounts') {
                    title = t(
                        '7a7c22806d2e093934b32eb2f5830c59',
                        'Invalid tenant'
                    );
                    content = t(
                        '2767167ac2fb836cbd439d42acd12e10',
                        'Could not retrieve accounts of this tenant.'
                    );
                }

                showError({
                    title: title,
                    content: content,
                    ttl: false,
                });
                dispatch({ type: AuthActions.Loading, payload: false });
                return false;
            }
        },
        [state, showError, t]
    );

    const checkAuth = useCallback(async (isAuthenticated) => {
        if (!isAuthenticated) {
            dispatch({ type: AuthActions.Loading, payload: true });
        }
        try {
            let data = await authApi.me();
            if (data.user) {
                Sentry.setUser({
                    email: data?.user?.preferred_username,
                    tenant_id: data?.tenant_id,
                    account_id: data?.account_id,
                });
                dispatch({ type: AuthActions.Login, payload: data });
            } else {
                dispatch({ type: AuthActions.Loading, payload: false });
            }
        } catch (ex) {
            dispatch({ type: AuthActions.Loading, payload: false });
        }
    }, []);

    const selectTenant = useCallback(
        async (tenantId) => {
            if (!state.isAuthenticated || state.isTenantSelected) return;

            dispatch({ type: AuthActions.Loading, payload: true });
            try {
                await authApi.selectTenant(tenantId);
                dispatch({ type: AuthActions.SelectTenant });
                checkAuth();
                setLastUsedTenantId(tenantId);
            } catch (ex) {
                let content = t(
                    '3c896035c1827afe4f80c1ebf54223d3',
                    'This tenant could not be selected.'
                );
                if (ex?.response?.data?.error === 'no_accounts') {
                    content = t(
                        '2767167ac2fb836cbd439d42acd12e10',
                        'Could not retrieve accounts of this tenant.'
                    );
                }

                showError({
                    title: t(
                        '7a7c22806d2e093934b32eb2f5830c59',
                        'Invalid tenant'
                    ),
                    content: content,
                    ttl: false,
                });
                logout();
            }
        },
        [state, checkAuth, showError, logout, t]
    );

    const selectAccount = useCallback(
        async (accountId) => {
            if (!state.isAuthenticated || !state.isTenantSelected) return;
            dispatch({
                type: AuthActions.SelectAccount,
                payload: accountId,
            });
            setLastUsedAccountId(state.attributes?.tenant_id, accountId);
        },
        [state]
    );

    const updateAttributes = useCallback(async (attributes) => {
        dispatch({
            type: AuthActions.UpdateAttributes,
            payload: attributes,
        });
    }, []);

    const getInitialTenant = () => {
        let tenant = {};

        if (!_.isEmpty(state.attributes?.tenants)) {
            const lastUsedTenantId = getLastUsedTenantId();

            if (!_.isUndefined(lastUsedTenantId)) {
                const lastUsedTenant = state.attributes.tenants.find(
                    (item) => item.umoTenantIdentifier === lastUsedTenantId
                );

                if (!_.isUndefined(lastUsedTenant)) {
                    tenant = lastUsedTenant;
                }
            }
            if (_.isEmpty(tenant)) {
                tenant = _.head(state.attributes?.tenants);
            }
        }

        return tenant;
    };

    return (
        <AuthContext.Provider
            value={{
                ...state,
                login,
                logout,
                oidcCallback,
                checkAuth,
                selectTenant,
                selectAccount,
                updateAttributes,
                getInitialTenant,
            }}
        >
            {children}
        </AuthContext.Provider>
    );
});
AuthProvider.displayName = 'AuthProvider';

export const useAuth = () => useContext(AuthContext);
export const AuthConsumer = AuthContext.Consumer;
