import './umo-alarm-control-panel.scss';
import { HeaderPanel } from '@carbon/react';
import { UmoAlarmControlPhone } from './umo-control-phone/umo-control-phone';
import { UmoAlarmControlDevice } from './umo-control-device/umo-control-device';
import { UmoAlarmControlClosingInformation } from './umo-control-closing-information/umo-control-closing-information';

export function UmoAlarmControlPanel({ expanded = false }) {
    const getStyle = () => {
        let classes = ['umo-alarm-control-panel'];
        if (!expanded) {
            classes.push('hidden');
        }
        return classes.join(' ');
    };

    return (
        <HeaderPanel expanded={expanded} className={getStyle()}>
            <UmoAlarmControlPhone />
            <UmoAlarmControlDevice />
            <UmoAlarmControlClosingInformation />
        </HeaderPanel>
    );
}
