import { forwardRef } from 'react';
import {
    EdsDropdown,
    EdsDropdownType,
    EdsForm,
    EdsFormColumn,
    EdsFormGroup,
    EdsTextArea,
} from '../../..';
import { useTranslation } from 'react-i18next';
import {
    getAlarmLocations,
    putDeviceAlarmLocationReallocation,
    useNotifications,
} from '../../../../features';
import { Row } from '@carbon/react';

const LocationRenumberingForm = forwardRef(
    (
        { deviceId, unavailableNumbers = [], postDataCallback, ...props },
        ref
    ) => {
        const { t } = useTranslation();
        const { showSuccess, showError } = useNotifications();

        const alarmLocationMappingCallback = (data) => {
            return {
                id: data.id,
                number: data.number,
                text: `${data.description} (${data.number})`,
            };
        };

        const getAlarmLocationsForDeviceLocation = async () => {
            const alarmLocations = await getAlarmLocations();
            return alarmLocations.filter(
                (item) => !unavailableNumbers.includes(item.number)
            );
        };

        const formDefinition = {
            deviceLocationNumber: {
                validation: {
                    type: 'dropdown',
                    required: true,
                },
                mapping: 'number',
            },
            umoLocationNumber: {
                validation: {
                    type: 'dropdown',
                    required: true,
                },
                mapping: 'number',
            },
            remarks: {
                validation: {
                    required: false,
                    max: 80,
                },
            },
        };

        const saveData = async (form) => {
            form.deviceId = deviceId;
            if (!form.id) {
                return await postDataCallback(form);
            } else {
                return await putDeviceAlarmLocationReallocation(form);
            }
        };

        const onSubmit = async (_event, form, isValid) => {
            if (!isValid) {
                throw false;
            }

            try {
                const response = await saveData(form);

                showSuccess({
                    title: t('536a056b710e94b16169efd17a4a657b', 'Saved'),
                    content: t(
                        '18a093083fc263a2e179b2571f604d93',
                        '{{entity}} is saved',
                        {
                            entity: t(
                                'a919b89cb9800f8c3e5a614f487e1b29',
                                'Alarm location renumbering'
                            ),
                        }
                    ),
                });

                return response;
            } catch (error) {
                showError({
                    title: t('cb5e100e5a9a3e7f6d1fd97512215282', 'Error'),
                    content: t(
                        'df0a9d46baf7315909e4389a04786e3d',
                        'Oops something went wrong'
                    ),
                });

                throw error;
            }
        };

        return (
            <EdsForm
                hideDefaultSubmit={props.hideDefaultSubmit}
                initValues={props.initValues}
                formDefinition={formDefinition}
                onSubmit={onSubmit}
                ref={ref}
            >
                <EdsFormGroup>
                    <Row>
                        <EdsFormColumn>
                            <EdsDropdown
                                name="deviceLocationNumber"
                                label={t(
                                    '9f8d225bc2126dc3c5ab5e40d3d3dc8d',
                                    'From location'
                                )}
                                type={EdsDropdownType.ComboBox}
                                getDataCallback={
                                    getAlarmLocationsForDeviceLocation
                                }
                                mappingCallback={alarmLocationMappingCallback}
                            />
                        </EdsFormColumn>
                        <EdsFormColumn>
                            <EdsDropdown
                                name="umoLocationNumber"
                                label={t(
                                    'bd7812e272ba6f993037a1ee259460be',
                                    'To location'
                                )}
                                type={EdsDropdownType.ComboBox}
                                getDataCallback={getAlarmLocations}
                                mappingCallback={alarmLocationMappingCallback}
                            />
                        </EdsFormColumn>
                    </Row>
                    <Row>
                        <EdsFormColumn fullWidth>
                            <EdsTextArea
                                name="remarks"
                                label={t(
                                    '911c185c8f3c475cdca2ef9cf12166da',
                                    'Remark'
                                )}
                                enableCounter={true}
                                maxCount={
                                    formDefinition?.remarks?.validation?.max
                                }
                                rows={2}
                            ></EdsTextArea>
                        </EdsFormColumn>
                    </Row>
                </EdsFormGroup>
            </EdsForm>
        );
    }
);

LocationRenumberingForm.displayName = 'LocationRenumberingForm';

export default LocationRenumberingForm;
