import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { EdsButton } from '../../eds-button/eds-button';
import { useForm } from '../form-context';
import { v4 as uuid } from 'uuid';
import { useEffectOnMount } from '../../../../features';
import { useTranslation } from 'react-i18next';

export const EdsFormRepeat = forwardRef((props, ref) => {
    const { removeFormDefinitionByPrefix, removeFormDefinitionsByFilter } =
        useForm();
    const [repeatIds, setRepeatIds] = useState([]);
    const { t } = useTranslation();

    const min = props.min ?? 0;
    const max = props.max ?? Number.MAX_SAFE_INTEGER;

    useEffectOnMount(() => {
        if (min === 0) {
            return;
        }
        for (let i = 0; i < min; i++) {
            addRepeat();
        }
    });

    const getPrefixedRepeatId = (repeatId) => {
        return props.prefix + '_' + repeatId;
    };

    const addRepeat = () => {
        if (repeatIds.length >= max) {
            return;
        }
        let newRepeatIds = [...repeatIds];
        newRepeatIds.push(uuid());
        setRepeatIds(newRepeatIds);
    };

    const removeRepeat = (repeatId) => {
        let index = repeatIds.indexOf(repeatId);
        if (repeatIds.length <= min || index === -1) {
            return;
        }
        removeFormDefinitionByPrefix(getPrefixedRepeatId(repeatId));
        let newRepeatIds = [...repeatIds];
        newRepeatIds.splice(index, 1);
        setRepeatIds(newRepeatIds);
    };

    const removeRepeatAll = () => {
        let prefixes = repeatIds.map((repeatId) =>
            getPrefixedRepeatId(repeatId)
        );
        removeFormDefinitionsByFilter((key) => prefixes.indexOf(key) === -1);
        setRepeatIds([]);
    };

    const removeRepeatEnabled = () => {
        return repeatIds.length > min;
    };

    useImperativeHandle(ref, () => ({
        addRepeat,
        removeRepeat,
        removeRepeatAll,
        removeRepeatEnabled,
    }));

    return (
        <>
            {repeatIds.map((repeatId) =>
                React.Children.map(props.children, (child) => {
                    if (child) {
                        return React.cloneElement(child, {
                            prefix: getPrefixedRepeatId(repeatId),
                            repeatId,
                        });
                    }
                })
            )}
            <EdsButton
                disabled={repeatIds.length >= max}
                kind={props.addButtonKind ?? 'tertiary'}
                onClick={() => {
                    addRepeat();
                }}
            >
                {props.addButtonLabel ??
                    t('34ec78fcc91ffb1e54cd85e4a0924332', 'Add')}
            </EdsButton>
        </>
    );
});
EdsFormRepeat.displayName = 'EdsFormRepeat';
