import { Navigate, useLocation } from 'react-router-dom';
import { useAuth } from './context';
import _ from 'lodash';
import { getLogger } from '../utils';
import { getLastUsedAccountId } from '../utils/last-used-tenants';

const logger = getLogger('AccountIdSelector');

export const AccountIdSelector = ({ children }) => {
    const { isAuthenticated, attributes, accountId } = useAuth();
    const location = useLocation();

    logger.log('accountId', accountId);

    if (!isAuthenticated) {
        logger.log('Not authenticated');
        return children;
    }

    if (!location.pathname.startsWith('/account')) {
        return children;
    }

    if (!_.isUndefined(accountId)) {
        let account = attributes.accounts?.find(
            (item) => `${item.id}` === accountId
        );

        if (!_.isUndefined(account)) {
            logger.log('Account is valid');
            return children;
        } else {
            logger.log('Redirect to select default account');
            return <Navigate to={`/account`} />;
        }
    }

    const lastUsedAccountId = getLastUsedAccountId(attributes.tenant_id);
    if (!_.isUndefined(lastUsedAccountId)) {
        logger.log('Redirect to last used account');
        return <Navigate to={`/account/${lastUsedAccountId}`} />;
    } else {
        let defaultAccount = attributes.accounts?.find(
            (account) => account.isDefault
        );
        if (!_.isUndefined(defaultAccount)) {
            logger.log('Redirect to default account');
            return <Navigate to={`/account/${defaultAccount.id}`} />;
        } else {
            let firstAccount = _.head(attributes.accounts);
            if (!_.isUndefined(firstAccount)) {
                logger.log('Redirect to first account');
                return <Navigate to={`/account/${firstAccount.id}`} />;
            }
        }
    }
    return children;
};
