import _ from 'lodash';
import i18n from '../../../features/i18n';
import { OverflowMenu, OverflowMenuItem } from '@carbon/react';
import { useTranslation } from 'react-i18next';
import './eds-language-switcher.scss';
import { Wikis } from '@carbon/icons-react';

export function EdsLanguageSwitcher({ onChangeCallback }) {
    const { t } = useTranslation();

    const languageTranslations = {
        'en-GB': t('cc9386e03685c49a9addea193dbdc88e', 'English (UK)'),
        'en-US': t('0a334547154e1ff8485210e7572a1d90', 'English (US)'),
        de: t('a6d414ac4f293187dd042025834925f7', 'German'),
        'nb-NO': t('23638322f44d7e5aa2e011a9698ce995', 'Norwegian Bokmål'),
        'nl-NL': t('b4355908e98e83e585d6faae9be33311', 'Dutch'),
        nn: t('be5d81f900adb73add638b1925a4c90f', 'Norwegian Nynorsk'),
        sv: t('c81a3177cd86c7371f0572a651a4ab70', 'Swedish'),
        da: t('5b119a961fcb523c81c25e8f79de2380', 'Danish'),
        fi: t('488f3a4d39c5803e5cc99f1c3d13bf31', 'Finnish'),
        ...(i18n.options.debug && {
            dev: 'Development',
            cimode: 'cimode',
        }),
    };

    // only render the component when there are multiple lanuages supported
    if (_.size(languageTranslations) < 2) {
        return null;
    }

    const mapLanguageToItem = (lng) => {
        return { id: lng, name: languageTranslations[lng] };
    };

    const getLanguageItems = () => {
        return i18n.options.supportedLngs
            .map((lng) => {
                return mapLanguageToItem(lng);
            })
            .filter((lng) => !_.isUndefined(lng.name))
            .sort((a, b) => a.name.localeCompare(b.name));
    };

    const handleOverflowMenuItemOnClick = (event) => {
        const languageItem = {
            id: event?.currentTarget?.id,
            name: event?.currentTarget?.getAttribute('data-language-name'),
        };

        i18n.changeLanguage(languageItem?.id);

        if (_.isFunction(onChangeCallback)) {
            onChangeCallback(languageItem);
        }
    };

    const getWrapperListItemStyle = (language) => {
        let classes = [];

        if (language.id === i18n.language) {
            classes.push('eds-active-language');
        }

        return classes.join(' ');
    };

    return (
        <div className="eds-language-switcher-wrapper">
            <OverflowMenu
                iconDescription={t(
                    '8512ae7d57b1396273f76fe6ed341a23',
                    'Language'
                )}
                menuOffset={{ left: 0 }}
                align={'bottom'}
                renderIcon={() => <Wikis size={20} />}
                size="lg"
                menuOptionsClass="eds-language-switcher-menu"
            >
                {getLanguageItems().map((language) => (
                    <OverflowMenuItem
                        key={language.id}
                        id={language.id}
                        wrapperClassName={getWrapperListItemStyle(language)}
                        data-language-name={language.name}
                        className="eds-language-menu-option"
                        itemText={language.name}
                        onClick={handleOverflowMenuItemOnClick}
                    />
                ))}
            </OverflowMenu>
        </div>
    );
}
