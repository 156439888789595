export const EdsTableFilterType = Object.freeze({
    MultiSelect: 'MULTI_SELECT',
    Range: 'RANGE',
    Dropdown: 'DROPDOWN',
    Text: 'TEXT',
    Email: 'EMAIL',
    PhoneNumber: 'PHONE_NUMBER',
    Number: 'NUMBER',
    YesNo: 'BOOLEAN_YES_NO',
    Date: 'DATE',
    DateRange: 'DATE_RANGE',
    Location: 'LOCATION',
    Group: 'GROUP',
    AlarmCriteria: 'ALARM_CRITERIA',
});

export const EdsTableFilterAlarmCriteriaType = Object.freeze({
    Alarm: 'ALARM',
    TechnicalAlarm: 'TECHINCAL_ALARM',
    CustomRanges: 'CUSTOM_RANGES',
    SpecificCriteria: 'SPECIFIC_CRITERIA',
});
