import { Stack } from '@carbon/react';
import './eds-container.scss';
import {
    EdsContainerButton,
    EdsContainerButtonType,
} from './eds-container-button/eds-container-button';
import _ from 'lodash';
import { EdsTableNoData } from '../eds-table/eds-table';
import { usePermissions } from '../../../features';

export function EdsContainer({
    show = true,
    linkLabel,
    showLinklabel = false,
    ...props
}) {
    const { isAllowed } = usePermissions();

    const getStyle = () => {
        let classes = ['eds-container'];
        if (_.isUndefined(props.withPadding) || props.withPadding) {
            classes.push('padding');
        }
        if (props.withBottomPadding) {
            classes.push('padding-bottom');
        }
        if (props.hidden) {
            classes.push('hidden');
        }
        if (props.step) {
            classes.push('step');
        }
        if (props.transparent) {
            classes.push('transparent');
        }

        if (!show) {
            classes.push('hidden');
        }
        return classes.join(' ');
    };

    const showActionEdit = () => {
        return (
            _.isFunction(props.onHeaderEditClick) &&
            (_.isUndefined(props.permissions?.edit) ||
                (!_.isUndefined(props.permissions?.edit) &&
                    isAllowed(props.permissions.edit)))
        );
    };

    const showActionLink = () => {
        return (
            _.isFunction(props.onHeaderLinkClick) &&
            (_.isUndefined(props.permissions?.link) ||
                (!_.isUndefined(props.permissions?.link) &&
                    isAllowed(props.permissions.link)))
        );
    };

    const showActionUnlink = () => {
        return (
            _.isFunction(props.onHeaderUnlinkClick) &&
            (_.isUndefined(props.permissions?.unlink) ||
                (!_.isUndefined(props.permissions?.unlink) &&
                    isAllowed(props.permissions.unlink)))
        );
    };

    const showActionAdd = () => {
        return (
            _.isFunction(props.onHeaderAddClick) &&
            (_.isUndefined(props.permissions?.new) ||
                (!_.isUndefined(props.permissions?.new) &&
                    isAllowed(props.permissions.new)))
        );
    };

    return (
        <div className={getStyle()}>
            <div className="container-buttons">
                {showActionAdd() && (
                    <EdsContainerButton
                        type={EdsContainerButtonType.Add}
                        onClick={props.onHeaderAddClick}
                        label={props.addlabel}
                    />
                )}

                {showActionEdit() && (
                    <EdsContainerButton
                        type={EdsContainerButtonType.Edit}
                        label={props.editLabel}
                        onClick={props.onHeaderEditClick}
                    />
                )}
                {showActionLink() && (
                    <EdsContainerButton
                        showLabel={showLinklabel}
                        type={EdsContainerButtonType.Link}
                        label={linkLabel}
                        onClick={props.onHeaderLinkClick}
                    />
                )}
                {showActionUnlink() && (
                    <EdsContainerButton
                        type={EdsContainerButtonType.Unlink}
                        onClick={props.onHeaderUnlinkClick}
                    />
                )}
            </div>
            {props.title && <h2>{props.title}</h2>}
            {props.children}
        </div>
    );
}

export function EdsContainerGrid({ children }) {
    return (
        <Stack gap={5} className="eds-container-grid">
            <>{children}</>
            <div className="fallback">
                <EdsContainer>
                    <EdsTableNoData emptyWithImage={true}></EdsTableNoData>
                </EdsContainer>
            </div>
        </Stack>
    );
}
