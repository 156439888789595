import { forwardRef, useEffect, useState } from 'react';
import EdsWizardContext from './wizard-context';
import { useTranslation } from 'react-i18next';
import { getLogger, useAuth, useModal } from '../../../features';
import _ from 'lodash';

const logger = getLogger('EdsWizardProvider');

const EdsWizardProvider = forwardRef((props, ref) => {
    const { formRef } = ref;
    const { children } = props;
    const { t } = useTranslation();
    const { attributes, updateAttributes, accountId } = useAuth();
    const [steps, setSteps] = useState([]);
    const [formDefinitions, setFormDefinitions] = useState([]);
    const [selectedIndex, setSelectedIndex] = useState(0);
    const { showModal } = useModal();

    const getStateKey = (accountId, name) => {
        return accountId + '/' + name;
    };

    const saveForm = () => {
        logger.log('[wizard]', 'Save form');
        if (_.isNil(formRef)) {
            return;
        }

        formRef.current
            ?.submitForm({})
            .then((resp) => {
                logger.log('[wizard]', 'Form submitted', resp);
                if (resp) {
                    discardSavedState();
                }
            })
            .catch((err) => {
                logger.log('Form submit error', err);
            });
    };

    const saveState = () => {
        if (!props.stateManagement) {
            return;
        }
        const stateKey = getStateKey(accountId, props.stateManagement.name());
        logger.log('[state-management]', 'Save State', stateKey);

        const formState = formRef.current?.getState();
        logger.log('[state-management]', 'Form State', formState);
        if (formState) {
            const formChanged = formRef.current?.isFormChanged();
            logger.log('formChanged', formChanged);
            if (!formChanged) {
                logger.log(
                    '[state-management]',
                    'Form is equal to default state -> discard state'
                );
                discardSavedState();
                return;
            }
            props.stateManagement.save(formState).then((response) => {
                logger.log(
                    '[state-management]',
                    'State has been saved',
                    response
                );
                if (!attributes?.saved_states.includes(stateKey)) {
                    updateAttributes({
                        saved_states: [...attributes.saved_states, stateKey],
                    });
                }
            });
        }
    };

    const discardSavedState = (discardOnServer = false) => {
        if (!props.stateManagement) {
            return;
        }
        const stateKey = getStateKey(accountId, props.stateManagement.name());

        if (attributes?.saved_states.includes(stateKey)) {
            if (discardOnServer) {
                props.stateManagement.clear().then(() => {
                    logger.log('[state-management] State has been discarded');
                });
            }
            updateAttributes({
                saved_states: attributes.saved_states.filter(
                    (item) => item !== stateKey
                ),
            });
        }
    };

    useEffect(() => {
        if (!props.stateManagement) {
            return;
        }
        const stateKey = getStateKey(accountId, props.stateManagement.name());
        logger.log(
            '[state-management]',
            'retrieve initial saved state',
            attributes?.saved_states,
            stateKey
        );

        if (attributes?.saved_states.includes(stateKey)) {
            props.stateManagement.get().then((savedState) => {
                formRef.current?.setSavedState(savedState);
            });
            showModal({
                title: t('07856050d71ef018a6b58ed4984099e3', 'Unsaved changes'),
                children: t(
                    '5f4d001ed0c3214aa3e02a233e37cb88',
                    'You have unsaved changes, do you want to continue?'
                ),
                primaryButtonText: t(
                    '7aa28ed115707345d0274032757e8991',
                    'Continue'
                ),
                secondaryButtonText: t(
                    '70f68bb2f748b85de6526bfe236ea1e9',
                    'Discard'
                ),
                danger: false,
                size: 'xs',
                onRequestSubmit: (event, removeModal) => {
                    logger.log('[state-management] onRequestSubmit');
                    removeModal();
                },
                onSecondarySubmit: (event, removeModal) => {
                    logger.log('[state-management] Discarding saved changes');
                    discardSavedState(true);
                    formRef.current?.resetForm();
                    removeModal();
                },
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.stateManagement]);

    const changeIndex = (newIndex) => {
        if (newIndex >= numberOfSteps()) {
            saveForm();
            return;
        }

        if (newIndex % 1 === 0 && newIndex >= 0 && newIndex < numberOfSteps()) {
            if (steps[selectedIndex] && steps[selectedIndex].id) {
                logger.log('[wizard] step', steps[selectedIndex]);
                logger.log('[wizard] formDefinitions', formDefinitions);

                if (formDefinitions[steps[selectedIndex].id]) {
                    const formDefinition =
                        formDefinitions[steps[selectedIndex].id];

                    formRef.current
                        ?.validateFormWithConfig(formDefinition)
                        .then((isValid) => {
                            if (isValid) {
                                saveState();

                                logger.log(
                                    '[wizard]',
                                    'Change Step',
                                    newIndex < selectedIndex
                                        ? 'backwards'
                                        : 'forward'
                                );
                                setSelectedIndex(newIndex);
                            } else {
                                if (newIndex < selectedIndex) {
                                    logger.log(
                                        '[wizard]',
                                        'Change Step backwards'
                                    );
                                    setSelectedIndex(newIndex);
                                } else {
                                    logger.log(
                                        '[wizard]',
                                        'Change Step forward prevented. Invalid form.'
                                    );
                                }
                            }
                        });
                } else {
                    //this step does not have a formDefinitions
                    setSelectedIndex(newIndex);
                }
            }
        }
    };

    const numberOfSteps = () => {
        if (steps) {
            return steps.length;
        }
        return 0;
    };

    const previousStep = () => {
        changeIndex(selectedIndex - 1);
    };

    const nextStep = () => {
        changeIndex(selectedIndex + 1);
    };

    const stepDisabledState = (index) => {
        return selectedIndex < index - 1;
    };

    const addStep = (step) => {
        if (!getStepDetails(step.id)) {
            setSteps((prevState) => [...prevState, step]);
        } else {
            logger.info('step already added.');
        }
    };

    const addSteps = (newSteps) => {
        if (_.isArray(newSteps)) {
            newSteps.map((step) => {
                addStep(step);
            });
        }
    };

    const getStepDetails = (stepId) => {
        const items = steps.filter((item) => {
            return item.id === stepId;
        });

        if (items.length === 0) {
            return false;
        }

        return items[0];
    };

    const indexOfStep = (stepId) => {
        let i = 0,
            stepIndex,
            ii = steps.length;
        for (i; i < ii; i++) {
            if (steps[i].id === stepId) {
                stepIndex = i;
                break;
            }
        }
        return stepIndex;
    };

    const isSelected = (stepId) => {
        return indexOfStep(stepId) === selectedIndex;
    };

    const addFormDefinitionForStep = (wizardStep, formDefinition, prefix) => {
        if (wizardStep) {
            let newDef = formDefinition;
            if (prefix) {
                newDef = {
                    [prefix]: formDefinition,
                };
            }

            setFormDefinitions((prevState) => {
                if (prevState?.[wizardStep]) {
                    logger.log(
                        '[addFormDefinitionForStep] prevState',
                        prevState
                    );
                    newDef = {
                        ...prevState[wizardStep],
                        ...newDef,
                    };
                }

                return {
                    ...prevState,
                    [wizardStep]: newDef,
                };
            });
        }
    };

    const hasStatemanagement = () => {
        return !_.isNil(props.stateManagement);
    };

    const cancelWizard = () => {
        if (!props.stateManagement) {
            return;
        }
        const stateKey = getStateKey(accountId, props.stateManagement.name());
        const formChanged = formRef.current?.isFormChanged();

        logger.log('formChanged', formChanged);

        if (!attributes?.saved_states.includes(stateKey) || !formChanged) {
            if (_.isFunction(props.onCancelClick)) {
                props.onCancelClick();
            }
            discardSavedState();
            return;
        }

        showModal({
            title: t('07856050d71ef018a6b58ed4984099e3', 'Unsaved changes'),
            children: t(
                '8d6fe738247b6e003ccba77a5a6acf84',
                'You have unsaved changes, are you sure you want to cancel and discard these changes?'
            ),
            primaryButtonText: t('a6105c0a611b41b08f1209506350279e', 'Yes'),
            secondaryButtonText: t('7fa3b767c460b54a2be4d49030b349c7', 'No'),
            danger: true,
            size: 'xs',
            onRequestSubmit: (event, removeModal) => {
                logger.log(
                    '[state-management]',
                    'Cancel Wizard -> Discarding saved changes'
                );
                discardSavedState(true);
                formRef.current?.resetForm();
                if (_.isFunction(props.onCancelClick)) {
                    props.onCancelClick();
                }
                removeModal();
            },
            onRequestClose: async (event, removeModal) => {
                removeModal();
            },
        });
    };

    const providerValue = {
        selectedIndex,
        steps,
        changeIndex,
        previousStep,
        nextStep,
        stepDisabledState,
        numberOfSteps,
        addSteps,
        addStep,
        getStepDetails,
        isSelected,
        addFormDefinitionForStep,
        saveState,
        cancelWizard,
        hasStatemanagement,
    };

    useEffect(() => {
        logger.log('[steps]', steps);
    }, [steps]);

    useEffect(() => {
        logger.log('[formDefinitions]', formDefinitions);
    }, [formDefinitions]);

    return (
        <EdsWizardContext.Provider value={providerValue}>
            {children}
        </EdsWizardContext.Provider>
    );
});

EdsWizardProvider.displayName = 'EdsWizardProvider';

export default EdsWizardProvider;
