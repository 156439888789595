import { Row } from '@carbon/react';
import { useEffect, useRef, useState } from 'react';
import {
    EdsDropdown,
    EdsDropdownType,
    EdsFormColumn,
    EdsFormGroup,
    EdsInputElementButton,
    EdsNotification,
    EdsTextInput,
} from '../../';
import {
    debounce,
    defaultDropdownMappingCallback,
    getCitiesByCountry,
    getCitiesByRegion,
    getCountries,
    getCountryTranslations,
    getDefaultCountry,
    getDistrictsByCity,
    getLogger,
    getRegion,
    getRegions,
    getResidenceTypes,
    getTenantGoogleMapsSettings,
    useModal,
    useNotifications,
} from '../../../../features';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { useForm } from '../form-context';
import { useEffectOnMount } from '../../../../features/react/hooks';
import CityForm from '../../../../routes/crm/maintenance/residence-settings/city/city-form';
import { AddLarge, Search } from '@carbon/icons-react';
import EdsAutocompleteAddressForm from './eds-autocomplete-address-form';
import { getAddressValidate } from '../../../../features/uds/address';

let logger = getLogger('EdsFormGroupAddress');

export function EdsFormGroupAddress({
    prefilled = false,
    validateAddress = false,
    ...props
}) {
    const [formDefinition, setFormDefinition] = useState([]);
    const addressNameRef = useRef(null);
    const streetNameRef = useRef(null);
    const houseNumberRef = useRef(null);
    const postalCodeRef = useRef(null);
    const addressField2Ref = useRef(null);
    const countriesRef = useRef(null);
    const regionsRef = useRef(null);
    const citiesRef = useRef(null);
    const districtsRef = useRef(null);
    const [countryId, setCountryId] = useState();
    const [regionId, setRegionId] = useState(null);
    const [cityId, setCityId] = useState();
    const [districtId, setDistrictId] = useState();
    const [streetName, setStreetName] = useState();
    const [houseNumber, setHouseNumber] = useState();
    const [postalCode, setPostalCode] = useState();
    const [apartmentNumber, setApartmentNumber] = useState();
    const [isUKAddressFormat, setIsUKAddressFormat] = useState(
        props.isUKAddressFormat ?? false
    );
    const [prefix] = useState(props.prefix ?? null);
    const checkInputsTimeoutId = useRef();
    const updateRegionTimeoutId = useRef();
    const updateCityTimeoutId = useRef();
    const validateAddressTimeoutId = useRef();
    const { checkInputsSection, getFormValue, updateFormValues } = useForm();
    const { showConfirmationModal, showFormModal } = useModal();
    const formRef = useRef(null);
    const tableRef = useRef(null);
    const { t } = useTranslation();
    const { showInfo } = useNotifications();

    const [mapsApiKey, setMapsApiKey] = useState();

    const countryTranslations = getCountryTranslations(t);

    const [addressValidationError, setAddressValidationError] = useState();

    const countryMappingCallback = (data) => {
        return {
            id: data.id,
            text: countryTranslations[data.countryCode] || data.name,
            countryCode: data.countryCode,
            isUKAddressFormat: data.isUKAddressFormat,
            postalCodeFormat: data.postalCodeFormat,
        };
    };

    const cityMappingCallback = (data) => {
        return { id: data.id, text: data.name, regionId: data.regionId };
    };

    const getMappedDefaultCountry = async () => {
        const defaultCountry = await getDefaultCountry();
        return defaultCountry ? countryMappingCallback(defaultCountry) : {};
    };

    useEffectOnMount(async () => {
        setFormDefinition(await getFormDefinition());

        const fetchMapsApiKey = async () => {
            const settings = await getTenantGoogleMapsSettings();
            if (!_.isEmpty(settings?.apiKey)) {
                setMapsApiKey(settings.apiKey);
            }
        };
        fetchMapsApiKey();
    });

    useEffect(() => {
        // Update regions and cities on country id change
        updateRegions();
        updateCities();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [countryId]);

    useEffect(() => {
        // Update cities on region id change
        updateCities();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [regionId]);

    useEffect(() => {
        // Update districts on city id change
        updateDistricts();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cityId]);

    useEffect(() => {
        debounce(
            validateAddressTimeoutId,
            () => {
                handleValidateAddress();
            },
            {
                trailing: true,
                delay: 300,
            }
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        cityId,
        districtId,
        streetName,
        houseNumber,
        postalCode,
        apartmentNumber,
        prefilled,
    ]);

    useEffect(() => {
        if (!isUKAddressFormat) {
            addressNameRef?.current?.updateValue(
                formDefinition?.addressName?.validation?.value ?? ''
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isUKAddressFormat]);

    useEffect(() => {
        if (prefilled) {
            prefillAddress();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [prefilled]);

    const handleValidateAddress = async () => {
        if (
            !validateAddress ||
            _.isUndefined(cityId) ||
            _.isEmpty(streetName) ||
            (props.showApartmentNumber &&
                prefilled &&
                _.isEmpty(apartmentNumber))
        ) {
            setAddressValidationError(undefined);
            return;
        }

        try {
            const address = {
                cityId: cityId,
                districtId: districtId,
                streetName: streetName,
                postalCode: postalCode,
                houseNumber: houseNumber,
                ...(props.showApartmentNumber && {
                    apartmentNumber: apartmentNumber,
                }),
            };

            const { addressIsUsed, existingResidences, existingSchemes } =
                await getAddressValidate(address);

            if (addressIsUsed) {
                const residenceCount = _.size(existingResidences);
                const schemeCount = _.size(existingSchemes);

                let error;
                if (residenceCount !== 0) {
                    if (residenceCount === 1) {
                        error = t(
                            '6ba4c0655378f7751a781eb19a954453',
                            'This address is already in use by a residence'
                        );
                    } else if (residenceCount > 1) {
                        error = t(
                            'ff150c3875da3e9bbbbadf30b0afafd1',
                            'This address is already in use by {{number}} residences',
                            { number: residenceCount }
                        );
                    }

                    if (schemeCount === 1) {
                        error += t(
                            '702d6ed471e9737aec81296bb59350ab',
                            " and by scheme '{{name}}'",
                            { name: existingSchemes[0].name }
                        );
                    } else if (schemeCount > 1) {
                        error += t(
                            'd6b651d0d28fb52f92045016f4f5a274',
                            ' and by the schemes {{names}}',
                            {
                                names: existingSchemes
                                    .map((r) => `'${r.name}'`)
                                    .join(', '),
                                interpolation: { escapeValue: false },
                            }
                        );
                    }
                } else if (schemeCount === 1) {
                    error = t(
                        '16a556155fd1487b3749cbd86f57ac1a',
                        "This address is already in use by scheme '{{name}}'",
                        { name: existingSchemes[0].name }
                    );
                } else if (schemeCount > 1) {
                    error = t(
                        'c935ad7f398e39fda64efa4d9b3fa011',
                        'This address is already in use by the schemes {{names}}',
                        {
                            names: existingSchemes
                                .map((r) => `'${r.name}`)
                                .join(', '),
                            interpolation: { escapeValue: false },
                        }
                    );
                }

                setAddressValidationError(error);
            } else {
                setAddressValidationError(undefined);
            }
        } catch (error) {
            logger.warn('Unable to validate address:', error);
        }
    };

    const updateDropdownRef = (dropdownRef, obj) => {
        const value = obj !== null ? { id: obj.id, text: obj.text } : {};
        dropdownRef?.current?.updateSelectedItem(value);
    };

    const prefillAddress = () => {
        if (!_.isFunction(props?.getPrefillDataCallback)) {
            return;
        }

        const address = props.getPrefillDataCallback();
        if (_.isEmpty(address)) {
            return;
        }

        addressNameRef?.current?.updateValue(address.addressName);
        streetNameRef?.current?.updateValue(address.streetName);
        houseNumberRef?.current?.updateValue(address.houseNumber);
        postalCodeRef?.current?.updateValue(address.postalCode);
        addressField2Ref?.current?.updateValue(address.addressField2);
        updateDropdownRef(countriesRef, address?.countryId);
        updateDropdownRef(regionsRef, address?.regionId);
        updateDropdownRef(citiesRef, address?.cityId);
        updateDropdownRef(districtsRef, address?.districtId);

        validateInputsSection(
            _.omit(formDefinition, [
                'apartmentNumber',
                ...(!isUKAddressFormat ? ['addressName'] : []),
            ])
        );
    };

    const validateInputsSection = (customDefinition) => {
        debounce(
            checkInputsTimeoutId,
            () => {
                customDefinition = _.isNull(prefix)
                    ? customDefinition
                    : {
                          [prefix]: customDefinition,
                      };

                checkInputsSection(customDefinition);
            },
            {
                trailing: true,
                delay: 300,
            }
        );
    };

    const updateRegions = async () => {
        const regions = [];
        let regionsResponse = null;
        if (_.isNumber(countryId)) {
            regionsResponse = await getRegions(countryId);
        }
        if (_.isArray(regionsResponse)) {
            regionsResponse.map((data) => {
                regions.push(defaultDropdownMappingCallback(data));
            });
        }
        regionsRef.current?.updateItems(regions);
    };

    const updateRegion = async (cityRegionId) => {
        if (_.isNil(cityRegionId) || !_.isNil(regionId)) {
            return;
        }

        regionsRef.current?.updateSelectedItem(cityRegionId);
    };

    const updateCities = async () => {
        const cities = [];
        let citiesResponse = null;
        if (_.isNumber(regionId)) {
            citiesResponse = await getCitiesByRegion(regionId);
        } else if (_.isNumber(countryId)) {
            citiesResponse = await getCitiesByCountry(countryId);
        }

        if (_.isArray(citiesResponse)) {
            citiesResponse.map((data) => {
                cities.push(cityMappingCallback(data));
            });
        }
        citiesRef.current?.updateItems(cities);
    };

    const updateDistricts = async () => {
        const districts = [];
        let districstResponse = null;
        if (_.isNumber(cityId)) {
            districstResponse = await getDistrictsByCity(cityId);
        }

        if (_.isArray(districstResponse)) {
            districstResponse.map((data) => {
                districts.push(defaultDropdownMappingCallback(data));
            });
        }
        districtsRef.current?.updateItems(districts);
    };

    const postalCodeFormatToRegExp = (postalCodeFormat) => {
        if (_.isNil(postalCodeFormat)) {
            return null;
        }

        let regExpStr = '^';
        [...postalCodeFormat].forEach((c) => {
            switch (c) {
                case 'L':
                    regExpStr += '[a-zA-Z]';
                    break;
                case '0':
                    regExpStr += '[0-9]';
                    break;
                case '_':
                    regExpStr += ' ';
                    break;
                default:
                    logger.warn(
                        'Unsupported postal code formatting character: ',
                        c
                    );
                    return null;
            }
        });

        regExpStr += '$';
        return new RegExp(regExpStr);
    };

    const getFormDefinition = async () => {
        return {
            addressName: {
                validation: {
                    required: false,
                    max: 256,
                },
            },
            streetName: {
                validation: {
                    required: props?.streetNameRequired ?? false,
                    max: 80,
                },
            },
            houseNumber: {
                validation: {
                    required: false,
                    max: 40,
                },
            },
            postalCode: {
                validation: {
                    required: false,
                    max: 10,
                    tests: [
                        {
                            name: 'postal-code-format',
                            message: '', // Custom error using testContext.createError(...)
                            func: async (value, testContext) => {
                                const address = prefix
                                    ? testContext?.options?.context?.form[
                                          prefix
                                      ]
                                    : testContext?.options?.context?.form;

                                const format =
                                    address?.countryId?.postalCodeFormat;
                                if (format && value) {
                                    const regExp =
                                        postalCodeFormatToRegExp(format);

                                    if (
                                        regExp !== null &&
                                        !regExp.test(value)
                                    ) {
                                        return testContext.createError({
                                            message: t(
                                                '5c6e1ca960ba37dfd6dfab4697533c86',
                                                'Please enter a valid postal code in the format {{format}}',
                                                { format: format }
                                            ),
                                        });
                                    }
                                }

                                return true;
                            },
                        },
                    ],
                },
            },
            addressField2: {
                validation: {
                    required: false,
                    max: 80,
                },
            },
            ...(props.showApartmentNumber && {
                apartmentNumber: {
                    validation: {
                        required: false,
                        max: 40,
                        tests: [
                            {
                                name: 'apartment-number-required',
                                message: t(
                                    '3f5f7f01887d47e3f71bf06e5f475e41',
                                    'Field is required'
                                ),
                                func: (value, testContext) => {
                                    const form =
                                        testContext?.options?.context?.form;
                                    if (form && form.useSchemeAddress) {
                                        return (
                                            !_.isUndefined(value) &&
                                            value.length
                                        );
                                    }
                                    return true;
                                },
                            },
                        ],
                    },
                },
            }),
            countryId: {
                value: await getMappedDefaultCountry(),
                validation: {
                    type: 'dropdown',
                    required: true,
                },
                mapping: 'id',
                dependents: ['postalCode'],
            },
            regionId: {
                validation: {
                    type: 'dropdown',
                    required: false,
                },
                mapping: 'id',
            },
            cityId: {
                validation: {
                    type: 'dropdown',
                    required: true,
                },
                mapping: 'id',
            },
            districtId: {
                validation: {
                    type: 'dropdown',
                    required: false,
                },
                mapping: 'id',
            },
            ...(props.showResidenceType && {
                residenceTypeId: {
                    validation: {
                        type: 'dropdown',
                        required: false,
                    },
                    mapping: 'id',
                },
            }),
            ...(!_.isUndefined(props.wizardStepId) && {
                wizardStepId: props.wizardStepId,
            }),
        };
    };

    const addCity = async (cityName) => {
        showFormModal({
            newTitle: t('9ee6a9784347aa722b2d67a701742317', 'Add new city'),
            editTitle: t('2b56351239e4a5028919e9550405db06', 'Edit city'),
            formData: {},
            formRef,
            tableRef,
            onSubmitFormResponse: (response, removeModal) => {
                const newCity = cityMappingCallback(response);

                if (regionId !== response.regionId) {
                    regionsRef.current?.updateSelectedItem(
                        response?.regionId ?? {}
                    );

                    debounce(
                        updateCityTimeoutId,
                        () => {
                            citiesRef.current?.updateSelectedItem(newCity);
                        },
                        {
                            trailing: true,
                            delay: 300,
                        }
                    );
                } else {
                    citiesRef.current?.addItem(newCity);
                }

                removeModal();
            },
            children: (
                <CityForm
                    countryId={countryId}
                    region={
                        !_.isNil(regionId)
                            ? await getRegion(regionId)
                            : undefined
                    }
                    ref={formRef}
                    initValues={{ name: cityName }}
                    hideDefaultSubmit={true}
                />
            ),
        });
    };

    const formatPostalCode = (postalCode, format) => {
        if (_.isNil(postalCode) || _.isNil(format)) {
            return postalCode;
        }

        const noSpacePostalCode = postalCode.replaceAll(' ', '');
        if (noSpacePostalCode.length !== format.replaceAll('_', '').length) {
            return postalCode;
        }

        let result = '';
        let index = 0;

        [...format].forEach((c) => {
            if (c === '_') {
                result += ' ';
            } else {
                result += noSpacePostalCode[index];
                index++;
            }
        });

        return result;
    };

    const autoCompleteAddressToAddress = async (autoCompleteAddress) => {
        const countries = await getCountries();
        let country = countries.find(
            (c) =>
                c.countryCode === autoCompleteAddress.countryCode?.toLowerCase()
        );

        if (_.isUndefined(country)) {
            country = countries.find(
                (c) => c.name === autoCompleteAddress.country
            );
        }

        if (_.isUndefined(country)) {
            showInfo({
                title: t(
                    'd67ded4eedbedfa9cdc207a622f81bdb',
                    'Country not found'
                ),
                content: t(
                    'ea69f5cc5f0889932d32cc82086b34fe',
                    'Autocomplete address can not be used as the country {{country}} is not found. Asks your administrator to add the country.',
                    { country: autoCompleteAddress.country }
                ),
                ttl: 8000,
            });
            return;
        }

        const countryId = countryMappingCallback(country);

        const cities = await getCitiesByCountry(countryId.id);
        let city = cities.find((c) => c.name === autoCompleteAddress.city);

        if (_.isUndefined(city)) {
            logger.log(
                `City ${autoCompleteAddress.city} not found. Do you want to add the city? Yes/No.`
            );

            showConfirmationModal({
                question: t(
                    'acbd797fdf18457496b9381fa0894324',
                    'The city {{city}} is not found in the list of available cities for the country {{country}}. Do you want to add the city?',
                    {
                        country: countryId.text,
                        city: autoCompleteAddress.city,
                        interpolation: { escapeValue: false },
                    }
                ),
                yesHandler: async () => {
                    return addCity(autoCompleteAddress.city);
                },
            });
        }

        return {
            streetName: autoCompleteAddress.streetName,
            houseNumber: autoCompleteAddress.houseNumber,
            postalCode: formatPostalCode(
                autoCompleteAddress.postalCode,
                countryId.postalCodeFormat
            ),
            countryId: countryId,
            cityId: _.isUndefined(city) ? {} : cityMappingCallback(city),
        };
    };

    const getPrefixedFieldName = (name) => {
        return _.isNil(props.prefix) ? name : `${props.prefix}.${name}`;
    };

    const lookupAddress = () => {
        showFormModal({
            title: t(
                '83e9307ec12c490202d81264b4656804',
                'Autocomplete address'
            ),
            formData: {},
            formRef,
            tableRef,
            primaryButtonText: t(
                '291b021ffd74b47f4292e40959aba948',
                'Select address'
            ),
            onSubmitFormResponse: async (response, removeModal) => {
                if (!_.isUndefined(response)) {
                    const address = await autoCompleteAddressToAddress(
                        response
                    );

                    if (!_.isUndefined(address)) {
                        updateFormValues({
                            [getPrefixedFieldName('streetName')]:
                                address.streetName,
                            [getPrefixedFieldName('houseNumber')]:
                                address.houseNumber,
                            [getPrefixedFieldName('postalCode')]:
                                address.postalCode,
                            [getPrefixedFieldName('countryId')]:
                                address.countryId,
                            [getPrefixedFieldName('regionId')]: {},
                            [getPrefixedFieldName('cityId')]: address.cityId,
                        });

                        setStreetName(address.streetName);
                        setHouseNumber(address.houseNumber);
                        setPostalCode(address.postalCode);
                        setCountryId(address.countryId.id);
                        setRegionId(undefined);
                        setCityId(address.cityId.id);

                        handleCityOnChange(address.cityId);

                        validateInputsSection(
                            _.pick(formDefinition, [
                                'streetName',
                                'houseNumber',
                                'postalCode',
                                'countryId',
                                'cityId',
                            ])
                        );
                    }
                }

                removeModal();
            },
            children: (
                <EdsAutocompleteAddressForm
                    ref={formRef}
                    hideDefaultSubmit={true}
                    apiKey={mapsApiKey}
                />
            ),
        });
    };

    const wrapInEdsInputElementButton = (children) => {
        if (_.isNil(mapsApiKey)) {
            return children;
        }

        return (
            <EdsInputElementButton
                {...{
                    label: t(
                        '83e9307ec12c490202d81264b4656804',
                        'Autocomplete address'
                    ),
                    onClickCallback: () => lookupAddress(),
                    renderedIcon: <Search />,
                }}
            >
                {children}
            </EdsInputElementButton>
        );
    };

    const getStreetField = () => {
        const streetInput = (
            <EdsTextInput
                ref={streetNameRef}
                name="streetName"
                label={t('1f4a9685e37e4f940d07a9f6b43dc83c', 'Street')}
                disabled={prefilled}
                onChangeCallback={(event) =>
                    setStreetName(event?.target?.value)
                }
            />
        );

        return (
            <EdsFormColumn>
                {!isUKAddressFormat
                    ? wrapInEdsInputElementButton(streetInput)
                    : streetInput}
            </EdsFormColumn>
        );
    };

    const getHouseNumberField = () => {
        return (
            <EdsFormColumn>
                <EdsTextInput
                    ref={houseNumberRef}
                    name="houseNumber"
                    label={t(
                        '61a0a33cf99a58d9cad0d5ed6deae269',
                        'House number'
                    )}
                    disabled={prefilled}
                    onChangeCallback={(event) =>
                        setHouseNumber(event?.target?.value)
                    }
                ></EdsTextInput>
            </EdsFormColumn>
        );
    };

    const handleCityOnChange = (city) => {
        setCityId(city?.id);

        if (!prefilled) {
            debounce(
                updateRegionTimeoutId,
                () => {
                    updateRegion(city?.regionId);
                },
                {
                    trailing: true,
                    delay: 300,
                }
            );
        }
    };

    return (
        <EdsFormGroup
            prefix={props.prefix}
            formDefinition={formDefinition}
            inStep={props.wizardStepId ?? false}
            label={
                props.label ?? t('884d9804999fc47a3c2694e49ad2536a', 'Address')
            }
        >
            {!_.isUndefined(addressValidationError) && (
                <EdsNotification
                    notification={{
                        hideCloseButton: true,
                        kind: 'warning',
                        title: addressValidationError,
                        inline: true,
                    }}
                />
            )}
            {isUKAddressFormat && (
                <Row>
                    <EdsFormColumn>
                        {wrapInEdsInputElementButton(
                            <EdsTextInput
                                name="addressName"
                                label={t(
                                    '8d6c792fc6a9a9019f65fe22d4871e82',
                                    'Address name'
                                )}
                                disabled={prefilled}
                            />
                        )}
                    </EdsFormColumn>
                </Row>
            )}
            <Row>
                {isUKAddressFormat && (
                    <>
                        {getHouseNumberField()} {getStreetField()}
                    </>
                )}
                {!isUKAddressFormat && (
                    <>
                        {getStreetField()} {getHouseNumberField()}
                    </>
                )}
            </Row>
            <Row>
                <EdsFormColumn>
                    <EdsTextInput
                        ref={postalCodeRef}
                        name="postalCode"
                        label={t(
                            'c8a03a9875d7fc4c023e1bebb23d07af',
                            'Postal code'
                        )}
                        disabled={prefilled}
                        onChangeCallback={(event) => {
                            setPostalCode(event?.target?.value);
                        }}
                    ></EdsTextInput>
                </EdsFormColumn>
                <EdsFormColumn>
                    <EdsTextInput
                        ref={addressField2Ref}
                        name="addressField2"
                        label={t(
                            '6bdbc10dfb9833c9215480c531f3bcd6',
                            'Additional address info'
                        )}
                        disabled={prefilled}
                    ></EdsTextInput>
                </EdsFormColumn>
            </Row>
            {props.showApartmentNumber && (
                <Row>
                    <EdsFormColumn>
                        <EdsTextInput
                            name="apartmentNumber"
                            label={t(
                                'c2127dce4ee7d70840144b856dd36fe8',
                                'Apartment number / Detail'
                            )}
                            forceRequired={prefilled}
                            onChangeCallback={(event) => {
                                setApartmentNumber(event?.target?.value);
                            }}
                        ></EdsTextInput>
                    </EdsFormColumn>
                </Row>
            )}
            <Row>
                <EdsFormColumn>
                    <EdsDropdown
                        ref={countriesRef}
                        name={'countryId'}
                        label={t('e909c2d7067ea37437cf97fe11d91bd0', 'Country')}
                        type={EdsDropdownType.ComboBox}
                        disabled={prefilled}
                        getDataCallback={getCountries}
                        mappingCallback={countryMappingCallback}
                        onChangeCallback={(event) => {
                            const countryId = event.selectedItem?.id;
                            setCountryId(countryId);
                            setIsUKAddressFormat(
                                event.selectedItem?.isUKAddressFormat ?? false
                            );

                            if (_.isUndefined(streetName)) {
                                setStreetName(
                                    getFormValue('streetName', prefix)
                                );
                            }
                            if (_.isUndefined(apartmentNumber)) {
                                setApartmentNumber(
                                    getFormValue('apartmentNumber', prefix)
                                );
                            }
                        }}
                    ></EdsDropdown>
                </EdsFormColumn>
                <EdsFormColumn>
                    <EdsDropdown
                        ref={regionsRef}
                        name={'regionId'}
                        label={t('960db2ed82202a9706b97775a4269378', 'Region')}
                        type={EdsDropdownType.ComboBox}
                        disabledOnEmpty={!prefilled}
                        disabled={prefilled}
                        onChangeCallback={(event) => {
                            const regionId = event.selectedItem?.id;
                            setRegionId(regionId);
                        }}
                    ></EdsDropdown>
                </EdsFormColumn>
            </Row>
            <Row>
                <EdsFormColumn>
                    <EdsDropdown
                        ref={citiesRef}
                        name={'cityId'}
                        type={EdsDropdownType.ComboBox}
                        label={t('4ed5d2eaed1a1fadcc41ad1d58ed603e', 'City')}
                        disabledOnEmpty={!prefilled}
                        disabled={prefilled}
                        onChangeCallback={(event) => {
                            handleCityOnChange(event.selectedItem);
                        }}
                        dropdownButtonProps={{
                            label: t('34ec78fcc91ffb1e54cd85e4a0924332', 'Add'),
                            onClickCallback: (value) => addCity(value),
                            renderedIcon: <AddLarge />,
                        }}
                    ></EdsDropdown>
                </EdsFormColumn>
                <EdsFormColumn>
                    <EdsDropdown
                        ref={districtsRef}
                        name={'districtId'}
                        type={EdsDropdownType.ComboBox}
                        label={t(
                            '6b77ef4b602800a89d88e6e3f93a322c',
                            'District'
                        )}
                        disabledOnEmpty={!prefilled}
                        disabled={prefilled}
                        onChangeCallback={(event) => {
                            setDistrictId(event.selectedItem?.id);
                        }}
                    ></EdsDropdown>
                </EdsFormColumn>
            </Row>
            {props.showResidenceType && (
                <Row>
                    <EdsFormColumn>
                        <EdsDropdown
                            type={EdsDropdownType.ComboBox}
                            name={'residenceTypeId'}
                            label={t(
                                '2d02d77d09fdf4c4466181594b8d8739',
                                'Residence type'
                            )}
                            getDataCallback={getResidenceTypes}
                        ></EdsDropdown>
                    </EdsFormColumn>
                </Row>
            )}
        </EdsFormGroup>
    );
}
