import './umo-control-phone.scss';
import {
    Asterisk,
    DirectionMerge,
    DirectionRight_01,
    Hashtag,
    Microphone,
    Number_0,
    Number_1,
    Number_2,
    Number_3,
    Number_4,
    Number_5,
    Number_6,
    Number_7,
    Number_8,
    Number_9,
    Pause,
    PhoneOff,
    PhoneVoice,
    Play,
    Switcher,
    UserAvatar,
} from '@carbon/icons-react';
import { useTranslation } from 'react-i18next';
import { UmoControlPanelComponent } from '../umo-control-panel-component/umo-control-panel-component';
import { IconButton, TextInput } from '@carbon/react';
import _ from 'lodash';
import { useState } from 'react';

export function UmoAlarmControlPhone() {
    const { t } = useTranslation();

    const [isDialerExpanded, setIsDialerExpanded] = useState(true);
    const toggleDialerExpanded = () => {
        setIsDialerExpanded(!isDialerExpanded);
    };

    return (
        <UmoControlPanelComponent
            icon={PhoneVoice}
            title={t('f7a42fe7211f98ac7a60a285ac3a9e87', 'Phone')}
            className="alarm-control-phone"
        >
            <div className="calls-list">
                <AlarmControlCallItem
                    title={'Gaby van de Valk Boume Valk Bouman'}
                    type={'Personal alarm'}
                />
                <AlarmControlCallItem
                    title={'Adam Andrew'}
                    type={'Outgoing call'}
                    onHold={true}
                />
            </div>
            <div className="call-buttons">
                <AlarmControlIconButton icon={Microphone} label="Mute" />
                <AlarmControlIconButton
                    icon={DirectionMerge}
                    label="Merge"
                    disabled={true}
                />
                <AlarmControlIconButton
                    icon={DirectionRight_01}
                    label="Forward"
                />
                <AlarmControlIconButton icon={UserAvatar} label="Contacts" />
                <AlarmControlIconButton
                    icon={Switcher}
                    label="Dailpad"
                    onClick={toggleDialerExpanded}
                    isSelected={isDialerExpanded}
                />
                <AlarmControlIconButton
                    icon={PhoneOff}
                    label="Hang up"
                    type={UmoAlarmControlIconButtonType.Danger}
                />
            </div>

            {isDialerExpanded && (
                <div className="call-dialer">
                    <TextInput name="number" labelText=" " id="number" />
                    <div className="dialer-numpad">
                        <AlarmControlDialerNumpadKey
                            icon={Number_1}
                            label={'1'}
                        />
                        <AlarmControlDialerNumpadKey
                            icon={Number_2}
                            label={'2'}
                        />
                        <AlarmControlDialerNumpadKey
                            icon={Number_3}
                            label={'3'}
                        />
                        <AlarmControlDialerNumpadKey
                            icon={Number_4}
                            label={'4'}
                        />
                        <AlarmControlDialerNumpadKey
                            icon={Number_5}
                            label={'5'}
                        />
                        <AlarmControlDialerNumpadKey
                            icon={Number_6}
                            label={'6'}
                        />
                        <AlarmControlDialerNumpadKey
                            icon={Number_7}
                            label={'7'}
                        />
                        <AlarmControlDialerNumpadKey
                            icon={Number_8}
                            label={'8'}
                        />
                        <AlarmControlDialerNumpadKey
                            icon={Number_9}
                            label={'9'}
                        />
                        <AlarmControlDialerNumpadKey
                            icon={Asterisk}
                            size={16}
                            label={'*'}
                        />
                        <AlarmControlDialerNumpadKey
                            icon={Number_0}
                            label={'0'}
                        />
                        <AlarmControlDialerNumpadKey
                            icon={Hashtag}
                            label={'#'}
                            size={16}
                        />
                    </div>
                </div>
            )}
        </UmoControlPanelComponent>
    );
}

export function AlarmControlDialerNumpadKey({ size = 20, icon, label }) {
    return (
        <div className="dialer-numpad-key">
            <AlarmControlIconButton icon={icon} size={size} label={label} />
        </div>
    );
}

export function AlarmControlCallItem({ title, type, onHold }) {
    const getStyle = () => {
        let classes = ['call-wrapper'];
        if (onHold) {
            classes.push('on-hold');
        }

        return classes.join(' ');
    };

    return (
        <div className={getStyle()}>
            <div className="call-details">
                <div className="detail-header">{title}</div>
                <div>00:00 {type}</div>
            </div>
            <div className="call-buttons">
                {onHold ? (
                    <AlarmControlIconButton icon={Play} label="Resume call" />
                ) : (
                    <AlarmControlIconButton icon={Pause} label="Pause call" />
                )}
            </div>
        </div>
    );
}

export const UmoAlarmControlIconButtonType = Object.freeze({
    Danger: 'Danger',
});

export function AlarmControlIconButton({
    size = 16,
    icon,
    label,
    disabled,
    type,
    onClick,
    isSelected,
}) {
    const getIcon = (Icon) => {
        if (_.isUndefined(Icon)) {
            return null;
        }
        return <Icon size={size} />;
    };

    const getStyle = () => {
        let classes = ['alarm-control-icon-button'];
        if (type === UmoAlarmControlIconButtonType.Danger) {
            classes.push('danger');
        }

        return classes.join(' ');
    };

    return (
        <IconButton
            className={getStyle()}
            label={label}
            kind={'ghost'}
            disabled={disabled}
            isSelected={isSelected}
            size={'sm'}
            onClick={(event) => {
                if (_.isFunction(onClick)) {
                    onClick(event);
                }
                event.stopPropagation();
            }}
        >
            {getIcon(icon)}
        </IconButton>
    );
}
