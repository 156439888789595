import {
    getRequest,
    postRequest,
    multipartPostRequest,
    putRequest,
    deleteRequest,
} from '../api';

export const getDeviceLocations = async (params) => {
    return getRequest('/maintenance/device/locations', params);
};

export const getDeviceLocation = async (id) => {
    return getRequest('/maintenance/device/locations/' + id);
};

export const postDeviceLocation = async (deviceLocation) => {
    return postRequest('/maintenance/device/locations', deviceLocation);
};

export const putDeviceLocation = async (deviceLocation) => {
    return putRequest(
        '/maintenance/device/locations/' + deviceLocation.id,
        deviceLocation
    );
};

export const deleteDeviceLocation = async (id) => {
    return deleteRequest('/maintenance/device/locations/' + id);
};

export const getDeviceTypes = async (params) => {
    return getRequest('/maintenance/device/types', params);
};

export const getDeviceType = async (id) => {
    return getRequest('/maintenance/device/types/' + id);
};

export const postDeviceType = async (deviceType) => {
    return postRequest('/maintenance/device/types', deviceType);
};

export const putDeviceType = async (deviceType) => {
    return putRequest('/maintenance/device/types/' + deviceType.id, deviceType);
};

export const deleteDeviceType = async (id) => {
    return deleteRequest('/maintenance/device/types/' + id);
};

export const getDeviceStates = async () => {
    return getRequest('/maintenance/device/states');
};

export const getDeviceState = async (id) => {
    return getRequest('/maintenance/device/states/' + id);
};

export const postDeviceState = async (deviceState) => {
    return postRequest('/maintenance/device/states', deviceState);
};

export const putDeviceState = async (deviceState) => {
    return putRequest(
        '/maintenance/device/states/' + deviceState.id,
        deviceState
    );
};

export const deleteDeviceState = async (id) => {
    return deleteRequest('/maintenance/device/states/' + id);
};

export const getDeviceManagers = async () => {
    return getRequest('/maintenance/device/managers');
};

export const getDeviceManager = async (id) => {
    return getRequest('/maintenance/device/managers/' + id);
};

export const postDeviceManager = async (deviceManager) => {
    return postRequest('/maintenance/device/managers', deviceManager);
};

export const putDeviceManager = async (deviceManager) => {
    return putRequest(
        '/maintenance/device/managers/' + deviceManager.id,
        deviceManager
    );
};

export const deleteDeviceManager = async (id) => {
    return deleteRequest('/maintenance/device/managers/' + id);
};

export const getDeviceManagerPhoneNumbers = async (id) => {
    return getRequest(`/maintenance/device/managers/${id}/phone-numbers`);
};

export const postDeviceManagerPhoneNumber = async (id, number) => {
    return postRequest(
        `/maintenance/device/managers/${id}/phone-numbers`,
        number
    );
};

export const getDeviceTypeImageUrl = (id, accountId) => {
    return `/api/maintenance/device/types/${id}/image?account_id=${accountId}`;
};

export const postDeviceTypeImage = async (
    devicetypeId,
    file,
    cbProgress,
    abortController
) => {
    let form = new FormData();
    form.append('file', file);
    let customHeaders = {};
    return multipartPostRequest(
        `/maintenance/device/types/${devicetypeId}/image`,
        form,
        customHeaders,
        cbProgress,
        abortController
    );
};

export const deleteDeviceTypeImage = async (devicetypeId) => {
    return deleteRequest(`/maintenance/device/types/${devicetypeId}/image`);
};
