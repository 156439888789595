import { useCallback, useReducer } from 'react';
import EdsTableFilterContext from './eds-table-filter-context';
import {
    EdsTableFilterReducer,
    EdsTableFilterReducerActions,
} from './eds-table-filter-reducer';
import { v4 as uuid } from 'uuid';
import { useEffectOnMount } from '../../../../features/react/hooks';
import { EdsTableFilterType } from './eds-table-filter-types';
import { EdsTableFilterOperator } from './eds-table-filter-operator';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import {
    defaultDropdownMappingCallback,
    getCity,
    getCountry,
    getCountryTranslations,
    getDistrict,
    getGroup,
    getLogger,
    getProject,
    getRegion,
    getSubgroup,
    getSubproject,
    toJSDate,
} from '../../../../features';
import { DateTime } from 'luxon';

const logger = getLogger('EdsTableFilterProvider');

const DATE_URL_FORMAT = 'yyyy-MM-dd';

export const urlEncodeFilters = (filters) => {
    let f = {};
    for (let index in filters) {
        let filter = filters[index];

        let item = {};
        if (
            !_.isEmpty(filter.operator) &&
            filter.operator !== EdsTableFilterOperator.Equals
        ) {
            item.o = filter.operator;
        }
        if (!_.isNil(filter.value?.from) && !_.isNil(filter.value?.to)) {
            item.f = urlEncodeValue(filter.value.from, filter.type);
            item.t = urlEncodeValue(filter.value.to, filter.type);
        } else if (!_.isEmpty(filter.value) || _.isInteger(filter.value)) {
            item.v = urlEncodeValue(filter.value, filter.type);
        }

        f[filter.column] = item;
    }
    return JSON.stringify(f);
};

const urlEncodeValue = (value, type) => {
    if (
        type === EdsTableFilterType.Date ||
        type === EdsTableFilterType.DateRange
    ) {
        const date = toJSDate(value, true);
        if (date instanceof Date) {
            return DateTime.fromJSDate(date).toFormat(DATE_URL_FORMAT);
        }
    }
    return value;
};

export const urlDecodeFilters = (f, availableFilters) => {
    if (_.isEmpty(f)) {
        return [];
    }

    let encodedFilters;
    try {
        encodedFilters = JSON.parse(f);
    } catch (err) {
        logger.warn('[urlDecodeFilters] Unable to decode filters:', err);
        return [];
    }

    let decodedFilters = [];
    for (let key in encodedFilters) {
        let originalFilter = availableFilters.find((item) => item.id === key);
        if (_.isNil(originalFilter)) {
            logger.warn(
                '[urlDecodeFilters] Unable to find filter for key:',
                key
            );
            continue;
        }

        let encoded = encodedFilters[key];
        let filter = {
            column: key,
            type: originalFilter.type,
            key: originalFilter.name,
        };

        if (
            !_.isNil(encoded.o) &&
            Object.values(EdsTableFilterOperator).indexOf(encoded.o) !== -1
        ) {
            filter.operator = encoded.o;
        } else {
            filter.operator = EdsTableFilterOperator.Equals;
        }

        if (!_.isNil(encoded.f) && !_.isNil(encoded.t)) {
            let from = urlDecodeValue(encoded.f, originalFilter.type);
            let to = urlDecodeValue(encoded.t, originalFilter.type);
            filter.value = { from, to };
        } else if (!_.isNil(encoded.v)) {
            filter.value = urlDecodeValue(encoded.v, originalFilter.type);
        }

        decodedFilters.push(filter);
    }

    return decodedFilters;
};

const urlDecodeValue = (value, type) => {
    if (
        (type === EdsTableFilterType.Date ||
            type === EdsTableFilterType.DateRange) &&
        _.isString(value)
    ) {
        return DateTime.fromFormat(value, DATE_URL_FORMAT).toJSON();
    }
    return value;
};

export const EdsTableFilterProvider = ({ children, ...props }) => {
    const { t } = useTranslation();
    const availableFilters = props.availableFilters || [];

    const [state, dispatch] = useReducer(
        EdsTableFilterReducer(props.searchFilterStore),
        {
            filters: [],
            searchTerm: null,
            sorting: null,
            savedFilters: [],
            id: uuid(),
        }
    );

    const removeFilter = useCallback((id) => {
        logger.log('[RemoveFilter]', id);

        dispatch({ type: EdsTableFilterReducerActions.RemoveFilter, id });
    }, []);

    const setSearchTerm = useCallback((searchTerm) => {
        dispatch({
            type: EdsTableFilterReducerActions.SetSearchTerm,
            payload: searchTerm,
        });
    }, []);

    const setSorting = useCallback((sorting) => {
        dispatch({
            type: EdsTableFilterReducerActions.SetSorting,
            payload: sorting,
        });
    }, []);

    const addFilter = useCallback(
        (filter) => {
            logger.log('[AddFilter]', filter);

            const addFilterAsync = async () => {
                const id = uuid();
                await formatFilter(filter);
                dispatch({
                    type: EdsTableFilterReducerActions.AddFilter,
                    payload: {
                        id,
                        onClose: () => removeFilter(id),
                        ...filter,
                    },
                });
            };
            addFilterAsync();
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [removeFilter]
    );

    const addFilters = useCallback(
        (filters) => {
            logger.log('[AddFilters]', filters);

            const addFiltersAsync = async () => {
                let payload = [];
                for (let index in filters) {
                    let filter = filters[index];
                    await formatFilter(filter);
                    const id = uuid();
                    payload.push({
                        id,
                        onClose: () => removeFilter(id),
                        ...filter,
                    });
                }
                dispatch({
                    type: EdsTableFilterReducerActions.AddFilters,
                    payload,
                });
            };
            addFiltersAsync();
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [removeFilter]
    );

    const applySavedFilter = useCallback(
        (id, filterSet) => {
            logger.log('Apply filterset', filterSet.searchTerm);

            let filters = filterSet.filters.map((filter) => {
                let id = uuid();
                return {
                    id,
                    onClose: () => removeFilter(id),
                    ...filter,
                };
            });
            dispatch({
                type: EdsTableFilterReducerActions.ApplySavedFilter,
                payload: {
                    id,
                    filters,
                    searchTerm: filterSet.searchTerm,
                    sorting: filterSet.sorting,
                },
            });
        },
        [removeFilter]
    );

    const formatOperator = (operator) => {
        switch (operator) {
            default:
            case EdsTableFilterOperator.Equals:
                return t('51c3f59625962b899c03595d6cdfb284', 'Equals');
            case EdsTableFilterOperator.NotEquals:
                return t('62558f7b07c034e992ca179a1b9b7e68', 'Not equals');
            case EdsTableFilterOperator.Empty:
                return t('bf943ad8b2ad1f26745e6236f04b74df', 'Is empty');
        }
    };

    const formatFilter = async (filter) => {
        if (!_.isEmpty(filter.key) && !_.isEmpty(filter.content)) {
            // Already formatted
            return;
        }

        let content = filter.value ?? '';

        let originalFilter = availableFilters.find(
            (f) => f.id === filter.column
        );

        if (_.isNil(originalFilter)) {
            logger.warn(
                '[formatFilter] Unable to find original filter:',
                filter,
                availableFilters
            );
            return;
        }

        filter.key = originalFilter.name;

        switch (filter.type) {
            case EdsTableFilterType.MultiSelect: {
                if (_.isFunction(originalFilter.getDataCallback)) {
                    let serverData = await originalFilter.getDataCallback();
                    let mappedData = serverData.map((data) => {
                        return _.isFunction(originalFilter.mappingCallback)
                            ? originalFilter.mappingCallback(data)
                            : defaultDropdownMappingCallback(data);
                    });
                    let texts = [];
                    for (let index in filter.value) {
                        let id = filter.value[index];
                        let data = mappedData.find((data) => data.id === id);
                        if (!_.isNil(data?.text)) {
                            texts.push(data.text);
                        }
                    }
                    content = texts.join(', ');
                }
                break;
            }
            case EdsTableFilterType.Dropdown: {
                let formattedOperator = formatOperator(filter.operator);
                if (filter.operator === EdsTableFilterOperator.Empty) {
                    content = formattedOperator;
                } else {
                    content = `${formattedOperator}: ${filter.value}`;
                }
                break;
            }
            case EdsTableFilterType.YesNo: {
                content = filter.value
                    ? t('a6105c0a611b41b08f1209506350279e', 'Yes')
                    : t('7fa3b767c460b54a2be4d49030b349c7', 'No');
                break;
            }
            case EdsTableFilterType.DateRange: {
                const dateFrom = toJSDate(filter.value?.from, true);
                const dateTo = toJSDate(filter.value?.to, true);
                if (dateFrom instanceof Date && dateTo instanceof Date) {
                    const format = t(
                        '418c23834fc746227d6ba6bbf181e8b5',
                        'MM/dd/yyyy'
                    );
                    let formattedDateFrom =
                        DateTime.fromJSDate(dateFrom).toFormat(format);

                    let formattedDateTo =
                        DateTime.fromJSDate(dateTo).toFormat(format);

                    content = t(
                        '517465b3e27939a0a35cd0228afa2b48',
                        '{{from}} to {{to}}',
                        {
                            from: formattedDateFrom,
                            to: formattedDateTo,
                            interpolation: { escapeValue: false },
                        }
                    );
                }
                break;
            }
            case EdsTableFilterType.Date: {
                let formattedOperator = formatOperator(filter.operator);
                if (filter.operator === EdsTableFilterOperator.Empty) {
                    content = formattedOperator;
                } else {
                    const date = toJSDate(filter.value, true);
                    if (date instanceof Date) {
                        let formattedDate = DateTime.fromJSDate(date).toFormat(
                            t('418c23834fc746227d6ba6bbf181e8b5', 'MM/dd/yyyy')
                        );
                        content = `${formattedOperator}: ${formattedDate}`;
                    }
                }
                break;
            }
            case EdsTableFilterType.Text:
            case EdsTableFilterType.Email:
            case EdsTableFilterType.PhoneNumber:
            case EdsTableFilterType.Number: {
                let formattedOperator = formatOperator(filter.operator);
                if (filter.operator === EdsTableFilterOperator.Empty) {
                    content = formattedOperator;
                } else {
                    content = `${formattedOperator}: ${filter.value}`;
                }
                break;
            }
            case EdsTableFilterType.Range: {
                content = t(
                    '517465b3e27939a0a35cd0228afa2b48',
                    '{{from}} to {{to}}',
                    {
                        from: filter.value?.from,
                        to: filter.value?.to,
                        interpolation: { escapeValue: false },
                    }
                );
                break;
            }
            case EdsTableFilterType.Location: {
                if (filter.value?.district) {
                    let district = await getDistrict(filter.value.district);
                    content =
                        t('6b77ef4b602800a89d88e6e3f93a322c', 'District') +
                        ': ' +
                        district?.name;
                } else if (filter.value?.city) {
                    let city = await getCity(filter.value.city);
                    content =
                        t('4ed5d2eaed1a1fadcc41ad1d58ed603e', 'City') +
                        ': ' +
                        city?.name;
                } else if (filter.value?.region) {
                    let region = await getRegion(filter.value.region);
                    content =
                        t('960db2ed82202a9706b97775a4269378', 'Region') +
                        ': ' +
                        region?.name;
                } else if (filter.value?.country) {
                    let country = await getCountry(filter.value.country);
                    const countryTranslations = getCountryTranslations(t);
                    content =
                        t('e909c2d7067ea37437cf97fe11d91bd0', 'Country') + ': ';
                    content += country?.countryCode
                        ? countryTranslations[country?.countryCode]
                        : country.name;
                }
                break;
            }
            case EdsTableFilterType.Group: {
                if (filter.value?.subproject) {
                    let subproject = await getSubproject(
                        filter.value.subproject
                    );
                    content =
                        t('bb07fd5abbe6511c40c7e036116d57de', 'Subproject') +
                        ': ' +
                        subproject?.name;
                } else if (filter.value?.project) {
                    let project = await getProject(filter.value.project);
                    content =
                        t('46f86faa6bbf9ac94a7e459509a20ed0', 'Project') +
                        ': ' +
                        project?.name;
                } else if (filter.value?.subgroup) {
                    let subgroup = await getSubgroup(filter.value.subgroup);
                    content =
                        t('ff90800b1b198e93f60b292ace3ffb00', 'Subgroup') +
                        ': ' +
                        subgroup?.name;
                } else if (filter.value?.group) {
                    let group = await getGroup(filter.value.group);
                    content =
                        t('db0f6f37ebeb6ea09489124345af2a45', 'Group') +
                        ': ' +
                        group?.name;
                }
                break;
            }
            case EdsTableFilterType.AlarmCriteria: {
                let types = [];
                if (filter.value.alarm) {
                    types.push(t('6486b4e98228b83d4b13d54febe5f170', 'Alarm'));
                }
                if (filter.value.technicalAlarm) {
                    types.push(
                        t('49155a18a3aabdd0182d51cf89aa6885', 'Technical alarm')
                    );
                }
                if (!_.isEmpty(filter.value.custom)) {
                    types.push(
                        t(
                            'ad8e9ec398490b7b96cb8a9640288416',
                            'Custom range(s)'
                        ) +
                            ' - ' +
                            filter.value.custom
                                .map((range) => `(${range.from}-${range.to})`)
                                .join(', ')
                    );
                }
                if (!_.isEmpty(filter.value.specific)) {
                    types.push(
                        t(
                            '74fb39e1813c7bd59d050bdec07a67e3',
                            'Specific criteria'
                        ) +
                            ' - ' +
                            filter.value.specific.join(', ')
                    );
                }
                content = types.join(', ');
                break;
            }
            default:
                break;
        }

        filter.content = content;
    };

    const formatSearchTerm = (searchTerm) => {
        return {
            key: t('94e12a3d717d4f1374012ede695cd81f', 'Search term'),
            content: searchTerm,
        };
    };

    const formatSorting = (sorting) => {
        let headerName = sorting.field;
        if (_.isArray(props.headers)) {
            for (let index in props.headers) {
                let header = props.headers[index];
                if (header.key === headerName || header.sort === headerName) {
                    headerName = header.header;
                    break;
                }
            }
        }
        return {
            key: t('8b4939bcf8f2d06f72b94cc1bd204569', 'Sorting'),
            content: `${headerName} (${
                sorting.direction === 'ASC'
                    ? t('9c9ab624360885fcf93b7643c93b6748', 'ascending')
                    : t('b19e9805fd7727c52ca04dfa3d24a2e5', 'descending')
            })`,
        };
    };

    const formatSavedFilterSetContent = (filterSet) => {
        let listItems = [];

        if (!_.isEmpty(filterSet.searchTerm)) {
            listItems.push(formatSearchTerm(filterSet.searchTerm));
        }

        if (!_.isEmpty(filterSet.sorting)) {
            listItems.push(formatSorting(filterSet.sorting));
        }

        let filters = filterSet.filters;
        for (let i in filters) {
            let filter = filters[i];
            listItems.push({ key: filter.key, content: filter.content });
        }

        return (
            <ul>
                {listItems.map((val) => (
                    <li key={val.key}>
                        {val.key} - {val.content}
                    </li>
                ))}
            </ul>
        );
    };
    const clearFilter = useCallback((id) => {
        logger.log('[clearFilters]');
        dispatch({
            type: EdsTableFilterReducerActions.ClearSavedFilter,
            id,
        });
    }, []);

    useEffectOnMount(async () => {
        if (_.isUndefined(props.searchFilterStore)) {
            return;
        }
        let savedFilterSets = await props.searchFilterStore.get();
        logger.log('[useEffectOnMount] Saved Filter Sets', savedFilterSets);

        let savedFilters = [];
        for (let id in savedFilterSets) {
            let savedFilterSet = savedFilterSets[id];
            let filterSet = {
                searchTerm: savedFilterSet.search_term,
                sorting: savedFilterSet.sorting,
                filters: savedFilterSet.filters,
            };
            for (let index in savedFilterSet.filters) {
                let filter = savedFilterSet.filters[index];
                await formatFilter(filter);
            }
            savedFilters.push({
                id,
                filterSet,
                content: formatSavedFilterSetContent(filterSet),
                onClick: () => {
                    logger.log('Use FilterSet', id);
                    applySavedFilter(id, filterSet);
                },
                onClose: () => clearFilter(id),
            });
        }

        dispatch({
            type: EdsTableFilterReducerActions.SetSavedFilter,
            payload: savedFilters,
        });
    });

    const providerValue = {
        ...state,
        addFilter,
        addFilters,
        removeFilter,
        clearFilter,
        setSearchTerm,
        setSorting,
    };

    return (
        <EdsTableFilterContext.Provider value={providerValue}>
            {children}
        </EdsTableFilterContext.Provider>
    );
};

EdsTableFilterProvider.displayName = 'EdsTableFilterProvider';
