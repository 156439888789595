import {
    EdsPage,
    EdsContainerTable,
    EdsTableDatetime,
    EdsAlarmStatus,
    EdsTableCriterium,
    EdsAlarmType,
} from '../../../components';
import { useTranslation } from 'react-i18next';
import { getDate } from '../../../features';
import './queue-page.scss';
import _ from 'lodash';

function QueuePage() {
    const { t } = useTranslation();

    const getTableRowStyle = (rowData) => {
        if (!_.isObject(rowData) || _.isNil(rowData?.assignee)) {
            return undefined;
        }

        return 'eds-table-row-alarm-assigned';
    };

    const getCriteriaCellStyleCallback = (rowData) => {
        if (!_.isObject(rowData) || _.isUndefined(rowData?.priority)) {
            return undefined;
        }

        return `eds-table-cell-prio-${rowData.priority.toLowerCase()}`;
    };

    return (
        <EdsPage title={t('3517d17f97589f6ee011e4d64ba4ec90', 'Alarm queue')}>
            <EdsContainerTable
                title={null}
                getTableRowStyleCallback={getTableRowStyle}
                headers={[
                    {
                        key: 'criteria',
                        header: t(
                            '15c46c6e8cc3bc71a1f7ac25fd043a38',
                            'Criteria'
                        ),
                        getTableCellStyleCallback: getCriteriaCellStyleCallback,
                    },
                    {
                        key: 'timeStamp',
                        header: t(
                            '01c76cca45a8dc7a3cd3097e734ee65b',
                            'Received on'
                        ),
                    },
                    {
                        key: 'name',
                        header: t(
                            '9fd352e406ac9fd2164c0ed50b35932b',
                            'Client name'
                        ),
                    },
                    {
                        key: 'medicalPriority',
                        header: t(
                            '64df27ddb333384955109454e26e112f',
                            'Medical priority'
                        ),
                    },
                    {
                        key: 'device',
                        header: t('913f9c49dcb544e2087cee284f4a00b7', 'Device'),
                    },
                    {
                        key: 'status',
                        header: t('9acb44549b41563697bb490144ec6258', 'Status'),
                    },
                ]}
                getDataCallback={() => {
                    return [
                        {
                            id: 1,
                            timeStamp: getDate({
                                minusMinutes: 1,
                                resetTime: false,
                            }),
                            criterion: 'Silent alarm',
                            priority: 'high',
                            name: 'Mr. Ian Ingram',
                            medicalPriority: 'Low',
                            device: 'P-500420',
                            assignee: 'Andre Haan',
                            alarmType: EdsAlarmType.Silent,
                        },
                        {
                            id: 2,
                            timeStamp: getDate(),
                            criterion: 'Fire / Smoke',
                            priority: 'high',
                            name: 'Mrs. Julia Janssen',
                            medicalPriority: 'Low',
                            device: 'OWISSELBEKEB3001',
                            assignee: null,
                            alarmType: EdsAlarmType.FireSmoke,
                        },
                        {
                            id: 3,
                            timeStamp: getDate(),
                            criterion: 'Fall detection',
                            priority: 'medium',
                            name: 'Mrs. Gabby van de Valk Bouman',
                            medicalPriority: 'High',
                            device: 'ATPHH-011134',
                            assignee: null,
                            alarmType: EdsAlarmType.Normal,
                        },
                        {
                            id: 4,
                            timeStamp: getDate({
                                minusMinutes: 3,
                                resetTime: false,
                            }),
                            criterion: 'Personal trigger / Pullcord',
                            priority: 'low',
                            name: 'Mr. Felix Frederiks',
                            medicalPriority: 'Low',
                            device: 'P-30029',
                            assignee: 'Marie-Louise Cornelisse',
                            alarmType: EdsAlarmType.Normal,
                        },
                        {
                            id: 5,
                            timeStamp: getDate(),
                            criterion: 'Technical alarm',
                            priority: 'low',
                            name: 'Mrs. Lilly Lammers',
                            medicalPriority: 'Low',
                            device: 'OHOFTERSTADE11108',
                            assignee: null,
                            alarmType: EdsAlarmType.Technical,
                        },
                    ];
                }}
                mappingCallback={(responseData) => {
                    return {
                        id: responseData.id,
                        timeStamp: (
                            <EdsTableDatetime value={responseData.timeStamp} />
                        ),
                        criteria: (
                            <EdsTableCriterium
                                label={responseData?.criterion}
                                priority={responseData?.priority}
                                alarmType={responseData?.alarmType}
                            />
                        ),
                        name: responseData?.name,
                        priority: responseData?.priority,
                        medicalPriority: responseData?.medicalPriority,
                        device: responseData?.device,
                        status: (
                            <EdsAlarmStatus
                                assignee={responseData?.assignee}
                                startDate={responseData.timeStamp}
                            />
                        ),
                        assignee: responseData?.assignee,
                    };
                }}
            />
        </EdsPage>
    );
}

export default QueuePage;
