import './umo-control-device.scss';
import { useTranslation } from 'react-i18next';
import {
    Connect,
    Devices,
    Unlocked,
    VolumeDown,
    VolumeUp,
} from '@carbon/icons-react';
import { UmoControlPanelComponent } from '../umo-control-panel-component/umo-control-panel-component';
import {
    Button,
    ButtonSet,
    ContentSwitcher,
    Switch,
    Toggle,
} from '@carbon/react';
import { useState } from 'react';

export function UmoAlarmControlDevice() {
    const { t } = useTranslation();

    const [fullDuplexEnabled, setFullDuplexEnabled] = useState(false);
    const toggleFullDuplex = () => {
        setFullDuplexEnabled(!fullDuplexEnabled);
    };

    return (
        <UmoControlPanelComponent
            icon={Devices}
            title={t('39aa5f03f2e211ee6e6088099d56a9c2', 'Device controls')}
            className="alarm-control-device"
        >
            <div className="device-details">
                <div className="detail-header">APG-300S</div>

                <div className="duplex-toggle">
                    <Toggle
                        labelA="Half duplex"
                        labelB="Full duplex"
                        toggled={fullDuplexEnabled}
                        id="toggle-1"
                        onClick={toggleFullDuplex}
                    />

                    {!fullDuplexEnabled && (
                        <div className="half-duplex-toggle">
                            <ContentSwitcher size="sm" onChange={() => {}}>
                                <Switch name="listening" text="Listening" />
                                <Switch name="speaking" text="Speaking" />
                            </ContentSwitcher>
                        </div>
                    )}
                </div>
            </div>
            <div className="device-controls">
                <ButtonSet stacked={true}>
                    <Button kind="ghost" renderIcon={Unlocked}>
                        Door open
                    </Button>
                    <Button kind="ghost" renderIcon={Unlocked}>
                        Key safe open
                    </Button>
                    <Button kind="ghost" renderIcon={VolumeUp}>
                        Volume up
                    </Button>
                    <Button kind="ghost" renderIcon={VolumeDown}>
                        Volume down
                    </Button>
                    <Button kind="ghost" renderIcon={Connect}>
                        Force connect
                    </Button>
                    <Button kind="ghost">BS Inelelink override speech</Button>
                </ButtonSet>
            </div>
        </UmoControlPanelComponent>
    );
}
