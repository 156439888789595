import { useRef } from 'react';
import {
    deleteDeviceAlarmCriteriaReallocation,
    getAlarmCriterionByNumber,
    getDeviceAlarmCriteriaReallocation,
    getLogger,
    useModal,
} from '../../../../features';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { EdsContainerTable } from '../../../eds';
import CriteriaRenumberingForm from './criteria-renumbering-form';

let logger = getLogger('UmoCriterioReallocationsTable');

export const UmoCriteriaReallocations = ({
    title,
    deviceId,
    getDataCallback,
    postDataCallback,
    ...props
}) => {
    const { t } = useTranslation();
    const tableRef = useRef(null);
    const formRef = useRef(null);
    const { showDeleteModal, showFormModal } = useModal();

    const showDataModal = (criteriaRenumbering = {}) => {
        const unavailableNumbers = tableRef.current
            .getRows()
            .map((row) => _.toNumber(row.deviceCriterion))
            .filter(
                (number) =>
                    number !== criteriaRenumbering?.deviceCriterion?.number
            );

        showFormModal({
            newTitle: t(
                '96a616e1dbe9bd0c625fb9852b266390',
                'New alarm criteria renumbering'
            ),
            editTitle: t(
                'a06f14b36311fc78b41d9608b8a51591',
                'Edit alarm criteria renumbering'
            ),
            formData: criteriaRenumbering,
            formRef,
            tableRef,
            onSubmitFormResponse: async (response, removeModal) => {
                if (response) {
                    tableRef.current?.fetchInitData();
                }
                removeModal();
            },
            children: (
                <CriteriaRenumberingForm
                    postDataCallback={postDataCallback}
                    ref={formRef}
                    initValues={criteriaRenumbering}
                    hideDefaultSubmit={true}
                    deviceId={deviceId}
                    unavailableNumbers={unavailableNumbers}
                />
            ),
        });
    };

    const getDataRow = async (row) => {
        try {
            let reallocation = await getDeviceAlarmCriteriaReallocation(row.id);

            if (_.isUndefined(reallocation.deviceCriterionName)) {
                let requests = [];
                requests.push(
                    getAlarmCriterionByNumber(reallocation.deviceCriterion)
                );
                requests.push(
                    getAlarmCriterionByNumber(reallocation.umoCriterion)
                );

                const response = await Promise.all(requests);

                reallocation.deviceCriterionName = response[0]?.name;
                reallocation.umoCriterionName = response[1]?.name;
            }

            const formData = {
                id: {
                    value: reallocation.id,
                },

                deviceCriterion: {
                    number: reallocation.deviceCriterion,
                    text: `${reallocation?.deviceCriterionName} (${reallocation?.deviceCriterion})`,
                },
                umoCriterion: {
                    number: reallocation.umoCriterion,
                    text: `${reallocation?.umoCriterionName} (${reallocation?.umoCriterion})`,
                },
                remarks: {
                    value: reallocation.remarks,
                },
            };

            showDataModal(formData);
        } catch (ex) {
            logger.warn(
                'getDeviceAlarmCriteriaReallocation UDS Exception:',
                ex
            );
        }
    };

    return (
        <EdsContainerTable
            title={
                title ??
                t(
                    '327b0f7e1b948f544f8bddc6716e9ae1',
                    'Alarm criteria renumbering'
                )
            }
            ref={tableRef}
            headers={[
                {
                    key: 'deviceCriterion',
                    header: t(
                        'df9cf0c8d13f0960d82aa9a111475de8',
                        'From criteria'
                    ),
                    sort: 'deviceCriterion',
                },
                {
                    key: 'umoCriterion',
                    header: t(
                        '4c836076e2338b7bd1a7d2e3f11478f2',
                        'To criteria'
                    ),
                    sort: 'umoCriterion',
                },
                {
                    key: 'remark',
                    header: t('911c185c8f3c475cdca2ef9cf12166da', 'Remark'),
                },
            ]}
            getDataCallback={getDataCallback}
            mappingCallback={(responseData) => {
                return {
                    id: responseData.id,
                    ...(_.isUndefined(responseData?.deviceCriterionName) && {
                        deviceCriterion: responseData?.deviceCriterion,
                        umoCriterion: responseData?.umoCriterion,
                    }),
                    ...(!_.isUndefined(responseData?.deviceCriterionName) && {
                        deviceCriterion: `${responseData?.deviceCriterionName} (${responseData?.deviceCriterion})`,
                        umoCriterion: `${responseData?.umoCriterionName} (${responseData?.umoCriterion})`,
                    }),
                    remark: responseData.remarks,
                };
            }}
            onHeaderAddClick={() => showDataModal()}
            onEditClick={(row) => {
                getDataRow(row);
            }}
            onDeleteClick={(row) => {
                let data = tableRef.current?.getRowData(row.id);
                showDeleteModal({
                    name: `${data.deviceCriterion} - ${data.umoCriterion}`,
                    tableRef,
                    row,
                    deleteHandler: async () => {
                        await deleteDeviceAlarmCriteriaReallocation(row.id);
                    },
                });
            }}
            permissions={props.permissions}
        />
    );
};
