import { redirect } from 'react-router-dom';
import { getPersonalCaregiver } from '../../../../../features';

export const pageLoader = async ({ params }) => {
    try {
        return await getPersonalCaregiver(params.personalCaregiverId);
    } catch (ex) {
        return redirect('..');
    }
};
