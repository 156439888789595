import './umo-control-closing-information.scss';
import { useTranslation } from 'react-i18next';
import { Alarm } from '@carbon/icons-react';
import { UmoControlPanelComponent } from '../umo-control-panel-component/umo-control-panel-component';
import {
    EdsDropdown,
    EdsDropdownType,
    EdsForm,
    EdsFormGroup,
    EdsTextArea,
} from '../../..';
import { useRef } from 'react';
import { getAlarmTypes } from '../../../../features';
import { Button, ButtonSet } from '@carbon/react';

export function UmoAlarmControlClosingInformation() {
    const { t } = useTranslation();
    const ref = useRef(null);

    return (
        <UmoControlPanelComponent
            icon={Alarm}
            title={t(
                '45bd120933702f2d0a5067a38e184650',
                'Alarm closing information'
            )}
            className="alarm-control-closing-information"
        >
            <EdsForm hideDefaultSubmit={true} initValues={{}} ref={ref}>
                <EdsFormGroup>
                    <EdsDropdown
                        type={EdsDropdownType.ComboBox}
                        hideTitleText={true}
                        name="alarmType"
                        placeholder={'Alarm type'}
                        getDataCallback={getAlarmTypes}
                    />
                    <EdsDropdown
                        type={EdsDropdownType.MultiSelect}
                        hideTitleText={true}
                        name="alarmReason"
                        label={'Alarm reason'}
                        getDataCallback={() => {
                            return {
                                id: 'test',
                                name: 'test',
                            };
                        }}
                    />
                    <EdsDropdown
                        type={EdsDropdownType.ComboBox}
                        hideTitleText={true}
                        name="optionalDetails"
                        placeholder={'Optional details'}
                        getDataCallback={() => {
                            return {
                                id: 'test',
                                name: 'test',
                            };
                        }}
                    />
                    <EdsTextArea
                        name="description"
                        placeholder={'Note'}
                        rows={4}
                    ></EdsTextArea>

                    <ButtonSet stacked={true}>
                        <Button kind="tertiary">History</Button>
                        <Button kind="tertiary">Follow up</Button>
                    </ButtonSet>
                </EdsFormGroup>
            </EdsForm>
        </UmoControlPanelComponent>
    );
}
