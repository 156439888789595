import {
    createSearchFilterStoreFunctions,
    createStateManagementFunctions,
    deleteRequest,
    getRequest,
    patchRequest,
    postRequest,
    putRequest,
} from '../api';

const basePath = '/devices';

export const getErrorStateLabels = (t, device) => {
    let labels = [];

    if (device.isDeviceBatteryLow) {
        labels.push(t('795a2f36733a538c79838086cdfb3bda', 'Battery low'));
    }
    if (device.isRadioTriggerBatteryLow) {
        labels.push(
            t('958344aedc5ddaa4103e05625f34aa51', 'Radio trigger battery low')
        );
    }
    if (device.isPowerFail) {
        labels.push(t('6b6b4b389af538d73124f902fa9fb385', 'Power fail'));
    }
    if (device.isDeviceBatteryFail) {
        labels.push(t('98da4ca1820d8bf537d5d0be985584cd', 'Battery fail'));
    }
    if (device.isRadioTriggerLost) {
        labels.push(
            t('5bf261874cd6c0e6494e1bcfa5b65664', 'Radio trigger lost')
        );
    }
    if (device.aliveState === 'NoAliveReceived') {
        labels.push(t('71d941c06bf060b5daebf8cd321422b8', 'No alive received'));
    }

    return labels.join(', ');
};

export const getDeviceSearch = async (params) => {
    return getRequest(`${basePath}/search`, params);
};

export const getDeviceByCode = async (code) => {
    return getRequest(`${basePath}/device-code/${code}`);
};

export const getDeviceByUID = async (uid) => {
    return getRequest(`${basePath}/uid/${uid}`);
};

export const saveDevice = async (device) => {
    return postRequest(`${basePath}`, device);
};

export const saveDeviceRange = async (device) => {
    return postRequest(`${basePath}/range`, device);
};

export const getDevice = async (id) => {
    return getRequest(`${basePath}/${id}`);
};

export const putDevice = async (device) => {
    return putRequest(`${basePath}/${device.id}`, device);
};

export const patchDevice = async (deviceId, data) => {
    return patchRequest(`${basePath}/${deviceId}`, data);
};

export const getDeviceNotes = async (deviceId, params) => {
    return getRequest(`${basePath}/${deviceId}/notes`, params);
};

export const postDeviceNote = async (deviceId, note) => {
    return postRequest(`${basePath}/${deviceId}/notes`, note);
};

export const getDeviceTags = async (deviceId, params) => {
    return getRequest(`${basePath}/${deviceId}/tags`, params);
};

export const postDeviceTag = async (deviceId, tag) => {
    return postRequest(`${basePath}/${deviceId}/tags`, tag);
};

export const getDeviceAlarms = async (deviceId) => {
    return getRequest(`${basePath}/${deviceId}/alarms`);
};

export const getDeviceAliveCalls = async (deviceId) => {
    return getRequest(`${basePath}/${deviceId}/alive-calls`);
};

export const getDeviceStateHistory = async (deviceId) => {
    return getRequest(`${basePath}/${deviceId}/history/state`);
};

export const getDeviceLocationHistory = async (deviceId) => {
    return getRequest(`${basePath}/${deviceId}/history/location`);
};

export const getDevicePeripherals = async (deviceId) => {
    return getRequest(`${basePath}/${deviceId}/peripherals`);
};

export const getDeviceAlarmCriteriaReallocations = async (deviceId) => {
    return getRequest(`${basePath}/${deviceId}/alarm-criteria-reallocations`);
};

export const postDeviceAlarmCriteriaReallocation = async (
    alarmCriteriaReallocation
) => {
    return postRequest(
        `${basePath}/${alarmCriteriaReallocation.deviceId}/alarm-criteria-reallocations`,
        alarmCriteriaReallocation
    );
};

export const getDeviceAlarmLocationReallocations = async (deviceId) => {
    return getRequest(`${basePath}/${deviceId}/alarm-location-reallocations`);
};

export const postDeviceAlarmLocationReallocation = async (
    alarmLocationReallocation
) => {
    return postRequest(
        `${basePath}/${alarmLocationReallocation.deviceId}/alarm-location-reallocations`,
        alarmLocationReallocation
    );
};

export const postDeviceValidate = async (params) => {
    return postRequest(`${basePath}/validate`, params);
};

export const postDeviceRangeValidate = async (params) => {
    return postRequest(`${basePath}/range-validate`, params);
};

export const stateManagement = {
    ...createStateManagementFunctions(basePath, 'devices'),
};

export const searchFilterStore = {
    ...createSearchFilterStoreFunctions(basePath, 'devices'),
};

export const getDeviceUnlinkValidate = async (deviceId) => {
    return getRequest(`${basePath}/${deviceId}/unlink/validate`);
};

export const postLinkDeviceToPeripheral = async (deviceId, peripheralId) => {
    return postRequest(
        `${basePath}/${deviceId}/peripherals/${peripheralId}/link`,
        null
    );
};

export const deleteUnlinkDeviceToPeripheral = async (
    deviceId,
    peripheralId,
    params
) => {
    return deleteRequest(
        `${basePath}/${deviceId}/peripherals/${peripheralId}/unlink`,
        params
    );
};
