import './umo-control-panel-component.scss';
import _ from 'lodash';

export function UmoControlPanelComponent({ icon, title, children, className }) {
    const getIcon = (Icon) => {
        if (_.isUndefined(Icon)) {
            return null;
        }
        return <Icon size="16" />;
    };

    return (
        <div className={`umo-control-panel-component ${className ?? ''}`}>
            <div className="component-header">
                {icon && (
                    <div className="component-header-icon">{getIcon(icon)}</div>
                )}
                <div className="component-header-title">{title}</div>
            </div>
            <div className="component-body">{children}</div>
        </div>
    );
}
